import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TimeOfDay } from 'src/app/shared/models/booking.models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-leave-manager-page',
  templateUrl: './leave-manager-page.component.html',
  styleUrls: ['./leave-manager-page.component.scss']
})
export class LeaveManagerPageComponent implements OnInit {

  selectedDate = new Date();

  startingHour = 8;
  endingHour = 17;
  timeslots: TimeOfDay[];

  currentDayBookedTimes: string[];

  wholeDay: boolean;

  constructor(
    private firestore: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.wholeDay = true;
    let res = await this.firestore.collection('leaves').doc(formatDate(this.selectedDate, 'yyyy-MM-dd', 'en')).get().toPromise();
    let data = res.data();
    this.timeslots = [];
    await this.getBookedTimes();
    for (var i = this.startingHour; i < this.endingHour + 1; i++) {
      let time = i < 10 ? '0' + i + ":00" : i + ":00";
      const isBooked = this.currentDayBookedTimes.includes(time);
      this.timeslots.push({
        time: time,
        selected: data == null ? false : data['slots'].includes(time),
        booked: isBooked
      });
    }

    for (var i = 0; i < this.timeslots.length; i++) {
      if (!this.timeslots[i].selected) this.wholeDay = false;
    }
  }

  async getBookedTimes() {

    const start = formatDate(this.selectedDate, 'yyyy-MM-dd', 'en');
    const tmpStart = new Date(start);
    const end = formatDate(tmpStart.setDate(tmpStart.getDate() + 1), 'yyyy-MM-dd', 'en');

    let res = await this.firestore.collection(
      'bookings',
      ref => ref.where('date', ">=", new Date(start)).where('date', "<", new Date(end))).get().toPromise();

    this.currentDayBookedTimes = [];
    for (var doc of res.docs) {
      const data = doc.data();

      if (data['validUntil'].toDate() < new Date() && !data['emailVerified']) {
        await this.firestore.collection('bookings').doc(doc.id).delete();
        continue;
      }

      this.currentDayBookedTimes.push(formatDate(data["date"].toDate(), "HH:mm", 'en'))
    }
  }

  wholeDayChanged() {
    for (var i = 0; i < this.timeslots.length; i++) {
      this.timeslots[i].selected = this.wholeDay;
    }
  }

  onDateChanged(event) {
    this.selectedDate = event;
    this.getData();
  }

  selectTimeslot(slot: TimeOfDay) {
    slot.selected = !slot.selected;
  }

  async saveClick() {
    var slots = [];
    this.timeslots.forEach(f => {
      if (f.selected) slots.push(f.time);
    });
    await this.firestore.collection('leaves').doc(formatDate(this.selectedDate, 'yyyy-MM-dd', 'en')).set({
      slots: slots
    });
    Swal.fire("Siker", "Szabadságok sikeresen el lettek mentve!", 'success');
  }

}
