import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { TimeOfDay } from '../shared/models/booking.models';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EmailTemplate } from '../shared/email-template';
import { formatDate } from '@angular/common';
import {
  DogType,
  DurationType,
  ServiceType,
} from '../shared/models/shared.models';
import { MockData } from '../shared/data';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-booking-confirm-page',
  templateUrl: './booking-confirm-page.component.html',
  styleUrls: ['./booking-confirm-page.component.scss'],
})
export class BookingConfirmPageComponent implements OnInit {
  startingHour = 8;
  endingHour = 17;

  page: number = 0;

  withBath: boolean = false;

  myDpOptions: IAngularMyDpOptions;

  selectedDatetime: Date;

  model: IMyDateModel = null;

  timeslots: TimeOfDay[];

  durations: DurationType[] = new MockData().durations;

  dogTypes: DogType[] = new MockData().dogTypes;

  services: ServiceType[] = new MockData().services;

  start: string;
  end: string;

  fullName: string;
  email: string;
  address: string;
  postcode: string;
  phone: string;
  selectedDogType: DogType;
  selectedService: ServiceType;
  selectedDuration: DurationType;
  dogName: string;
  dogAge: string;
  dogBreed: string;
  inSalon: boolean = false;

  price: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
    private analytics: AngularFireAnalytics
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.start = queryParams.get('datetime');
    });

    this.analytics.logEvent('bookingStarted');
  }

  formatTime(timeString: string): string {
    const [hourString, minute] = timeString.split(':');
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' am' : ' pm');
  }

  onDogTypeChange(id: number) {
    try {
      this.selectedDogType = this.dogTypes.filter((d) => d.id == id)[0];
    } catch (e) {
      this.selectedDogType = null;
    }
  }

  onServiceChange(id: number) {
    try {
      this.selectedService = this.services.filter((s) => s.id == id)[0];
    } catch (e) {
      this.selectedService = null;
    }
  }

  onBathChange(value) {
    console.log(value);
    console.log(this.withBath);
  }

  /* onSalonChange(value) {
    console.log(value);
    console.log(this.inSalon);
  } */

  getPrice(): number {
    var price = 0;
    if (this.selectedDogType == null && this.selectedService == null) price = 0;
    if (this.selectedDogType != null && this.selectedService == null)
      price = this.selectedDogType.price;
    if (this.selectedDogType == null && this.selectedService != null)
      price = this.selectedService.price;
    if (this.selectedDogType != null && this.selectedService != null)
      price = this.selectedDogType.price + this.selectedService.price;
    if (this.withBath) price += 5;

    if (this.inSalon) price += 10;

    return price;
  }

  getDurationText(): string {
    if (this.selectedDogType == null && this.selectedService == null)
      return null;
    if (this.selectedDogType != null && this.selectedService == null)
      return null;
    if (this.selectedDogType == null && this.selectedService != null)
      return null;

    this.selectedDuration = this.durations.filter(
      (d) =>
        d.dogId == this.selectedDogType.id &&
        d.serviceId == this.selectedService.id
    )[0];
    return this.inSalon
      ? this.selectedDuration.durationTextSalon
      : this.selectedDuration.durationText;
  }

  getDurationHour(): number {
    if (this.inSalon) {
      return this.selectedDuration.durationHourSalon;
    }

    return this.selectedDuration.durationHour;
  }

  async onDateChanged(event: IMyDateModel) {
    this.selectedDatetime = event.singleDate.jsDate;

    // formDate in yyyy-MM-dd
    const start = formatDate(this.selectedDatetime, 'yyyy-MM-dd', 'en');
    const tmpStart = new Date(start);
    const end = formatDate(
      tmpStart.setDate(tmpStart.getDate() + 1),
      'yyyy-MM-dd',
      'en'
    );

    // get back bookings from firebase
    let res = await this.firestore
      .collection('bookings', (ref) =>
        ref
          .where('date', '>=', new Date(start))
          .where('date', '<', new Date(end))
      )
      .get()
      .toPromise();

    // get back leaves from firebase
    let leavesRes = await this.firestore
      .collection('leaves')
      .doc(formatDate(this.selectedDatetime, 'yyyy-MM-dd', 'en'))
      .get()
      .toPromise();
    let leavesData = leavesRes.data();

    var currentDayBookedTimes: any[] = [];

    if (leavesData != null && leavesData['slots'] != null) {
      for (var leaveSlot of leavesData['slots']) {
        var hour = (
          Number(leaveSlot.split(':')[0]) -
          this.getDurationHour() +
          1
        ).toString();
        hour = hour.length < 2 ? '0' + hour : hour;
        currentDayBookedTimes.push({
          time: hour + ':00',
          durationHour: this.getDurationHour(),
        });
        currentDayBookedTimes.push({ time: leaveSlot });
      }
    }

    for (var doc of res.docs) {
      const data = doc.data();
      const date = formatDate(data['date'].toDate(), 'HH:mm', 'en');

      if (data['validUntil'].toDate() < new Date() && !data['emailVerified']) {
        await this.firestore.collection('bookings').doc(doc.id).delete();
        continue;
      }

      var hour = (
        Number(date.split(':')[0]) -
        this.getDurationHour() +
        1
      ).toString();
      var duration = this.getDurationHour();
      if (Number(hour) < this.startingHour) {
        duration = this.getDurationHour() - (this.startingHour - Number(hour));
        hour = this.startingHour.toString();
      }
      hour = hour.length < 2 ? '0' + hour : hour;
      currentDayBookedTimes.push({
        time: hour + ':00',
        durationHour: duration,
      });
      currentDayBookedTimes.push({
        time: date,
        durationHour: data['durationHour'],
      });
    }

    // Before end of the day, no more bookings available
    // TODO: enable to 2 or 3 hours duration
    if (this.getDurationHour() >= 4) {
      var eHour = (this.endingHour - this.getDurationHour() + 3).toString();
      eHour = eHour.length < 2 ? '0' + eHour : eHour;
      currentDayBookedTimes.push({
        time: eHour + ':00',
        durationHour: this.getDurationHour(),
      });
    }

    var date = new Date(
      event.singleDate.date.year,
      event.singleDate.date.month - 1,
      event.singleDate.date.day
    );
    let isWeekday = date.getDay() > 0 && date.getDay() < 6;

    // disable full day on tuesday and wednesday. It is changeable based on user.
    let fullDayDisabled = date.getDay() == 2 || date.getDay() == 3;
    this.generateTimeslots(currentDayBookedTimes, isWeekday, fullDayDisabled);
  }

  generateTimeslots(
    currentDayBookedTimes: any[],
    isWeekday: boolean,
    fullDayDisabled: boolean
  ) {
    this.timeslots = [];
    for (var i = this.startingHour; i < this.endingHour + 1; i++) {
      let time = i < 10 ? '0' + i + ':00' : i + ':00';
      var enable = isWeekday && i < 17;

      if (fullDayDisabled) {
        enable = true;
      }

      this.timeslots.push({
        time: time,
        selected: false,
        booked: enable,
      });
    }

    for (var currentDayBooked of currentDayBookedTimes) {
      let res = this.timeslots.find((t) => t.time == currentDayBooked.time);
      if (!res) continue;
      res.booked = true;
      let index = this.timeslots.indexOf(res);

      if (currentDayBooked?.durationHour == null) continue;
      for (var j = 0; j < currentDayBooked['durationHour']; j++) {
        if (index > this.timeslots.length - 1) continue;
        this.timeslots[index++].booked = true;
      }
    }
  }

  selectTimeslot(slot: TimeOfDay) {
    if (slot.selected) {
      slot.selected = false;
      return;
    }
    for (var time of this.timeslots) time.selected = false;
    slot.selected = true;
    this.selectedDatetime.setHours(
      Number(slot.time.split(':')[0]),
      Number(slot.time.split(':')[1])
    );
  }

  async isTimeslotStillAvailable(): Promise<boolean> {
    var available: boolean = true;

    const start = formatDate(this.selectedDatetime, 'yyyy-MM-dd', 'en');
    const tmpStart = new Date(start);
    const end = formatDate(
      tmpStart.setDate(tmpStart.getDate() + 1),
      'yyyy-MM-dd',
      'en'
    );

    let res = await this.firestore
      .collection('bookings', (ref) =>
        ref
          .where('date', '>=', new Date(start))
          .where('date', '<', new Date(end))
      )
      .get()
      .toPromise();

    let leavesRes = await this.firestore
      .collection('leaves')
      .doc(formatDate(this.selectedDatetime, 'yyyy-MM-dd', 'en'))
      .get()
      .toPromise();
    let leavesData = leavesRes.data();

    var currentDayBookedTimes: any[] = [];

    if (leavesData != null && leavesData['slots'] != null) {
      for (var leaveSlot of leavesData['slots']) {
        var hour = (
          Number(leaveSlot.split(':')[0]) -
          this.getDurationHour() +
          1
        ).toString();
        hour = hour.length < 2 ? '0' + hour : hour;
        currentDayBookedTimes.push({
          time: hour + ':00',
          durationHour: this.getDurationHour(),
        });
        currentDayBookedTimes.push({ time: leaveSlot });
      }
    }

    for (var doc of res.docs) {
      const data = doc.data();
      const date = formatDate(data['date'].toDate(), 'HH:mm', 'en');

      var hour = (
        Number(date.split(':')[0]) -
        this.getDurationHour() +
        1
      ).toString();
      var duration = this.getDurationHour();
      if (Number(hour) < this.startingHour) {
        duration = this.getDurationHour() - (this.startingHour - Number(hour));
        hour = this.startingHour.toString();
      }
      hour = hour.length < 2 ? '0' + hour : hour;
      currentDayBookedTimes.push({
        time: hour + ':00',
        durationHour: duration,
      });
      currentDayBookedTimes.push({
        time: date,
        durationHour: data['durationHour'],
      });
    }

    var timesWouldBooked = [];
    var time = formatDate(this.selectedDatetime, 'HH:mm', 'en');
    for (var i = 0; i < this.getDurationHour(); i++) {
      var h = (Number(time.split(':')[0]) + i).toString();
      h = h.length < 2 ? '0' + h : h;
      timesWouldBooked.push(h + ':' + time.split(':')[1]);
    }
    for (var c of currentDayBookedTimes) {
      if (timesWouldBooked.includes(c.time)) available = false;
    }

    return available;
  }

  async finishClick() {
    if (this.page == 0) {
      if (this.fullName == null || this.fullName == '') {
        Swal.fire('Oops', 'Name is required', 'error');
        return;
      }
      if (!this.inSalon && (this.postcode == null || this.postcode == '')) {
        Swal.fire('Oops', 'Postalcode is required', 'error');
        return;
      }
      if (!this.inSalon && (this.address == null || this.address == '')) {
        Swal.fire('Oops', 'Address is required', 'error');
        return;
      }
      const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i;
      if (this.email == null || !expression.test(this.email)) {
        Swal.fire('Oops', 'Valid email is required', 'error');
        return;
      }
      if (this.selectedDogType == null) {
        Swal.fire('Oops', 'Please choose your dog type', 'error');
        return;
      }
      if (this.selectedService == null) {
        Swal.fire('Oops', 'Please select a service', 'error');
        return;
      }
      if (this.dogName == null || this.dogName == '') {
        Swal.fire('Oops', "Dog's name is required", 'error');
        return;
      }
      if (this.dogAge == null || this.dogAge == '') {
        Swal.fire('Oops', "Dog's age is required", 'error');
        return;
      }
      if (this.dogBreed == null || this.dogBreed == '') {
        Swal.fire('Oops', "Dog's breed is required", 'error');
        return;
      }
      this.page = 1;
      this.timeslots = null;
      this.selectedDatetime = null;

      this.myDpOptions = {
        dateRange: false,
        dateFormat: 'dd.mm.yyyy',
        inline: true,
        //disableWeekdays: this.inSalon ? ['su', 'mo', 'tu', 'we', 'th'] : ['fr', 'sa', 'su'],
        disableUntil: {
          year: moment().year(),
          month: moment().month() + 1,
          day: moment().date() + 1,
        },
        disableSince: {
          year: moment().add('weeks', 6).year(),
          month: moment().add('weeks', 6).month() + 1,
          day: moment().add('weeks', 6).date(),
        },
      };

      this.analytics.logEvent('bookingTimeslotCheck');

      return;
    }
    var selectedTimeslot = false;
    if (this.timeslots != null) {
      for (var t of this.timeslots) {
        if (t.selected) selectedTimeslot = true;
      }
    }
    if (
      this.timeslots == null ||
      this.timeslots.length == 0 ||
      !selectedTimeslot
    ) {
      Swal.fire('Oops', 'Select an available timeslot', 'error');
      return;
    }

    let available = await this.isTimeslotStillAvailable();
    if (!available) {
      Swal.fire({
        icon: 'error',
        title: 'Booking Failed',
        html: 'Looks like someone booked in your timeslot Refresh your browser and try again in a different time.',
      });
      return;
    }

    try {
      this.spinner.show();
      var validUntil = new Date();
      validUntil.setMinutes(validUntil.getMinutes() + 10);
      let newBooking = await this.firestore.collection('bookings').add({
        name: this.fullName,
        email: this.email,
        address: this.address ?? '',
        postcode: this.postcode ?? '',
        phone: this.phone,
        dogType: this.selectedDogType.name,
        service: this.selectedService.name,
        price: this.getPrice(),
        date: this.selectedDatetime,
        durationHour: this.getDurationHour(),
        durationMinute: this.selectedDuration.durationMin,
        dogName: this.dogName,
        dogAge: this.dogAge,
        dogBreed: this.dogBreed,
        bookedAt: new Date(),
        validUntil: validUntil,
        emailVerified: false,
        withBath: this.withBath,
        inSalon: this.inSalon,
      });

      await this.firestore.collection('mail').add({
        to: this.email,
        message: {
          subject: 'Booking confirmation',
          html: new EmailTemplate().getSuccessEmail(
            this.fullName,
            formatDate(this.selectedDatetime, 'dd/MM/yyyy h:mm a', 'en'),
            this.address,
            this.postcode,
            this.getPrice(),
            this.selectedDogType.name,
            this.selectedService.name,
            newBooking.id,
            this.inSalon
              ? this.selectedDuration.durationTextSalon
              : this.selectedDuration.durationText,
            this.dogName,
            this.dogAge,
            this.dogBreed,
            this.withBath,
            this.inSalon
          ),
        },
      });
      let res = true;
      this.spinner.hide();

      if (res) {
        await Swal.fire({
          html: "<h1 style='color:red'>Check your mailbox (or spam), and click on the received link to confirm your booking.</h1>",
          imageUrl: 'assets/images/illustrations/dog_groom.png',
        });
        this.router.navigate(['']);
        this.spinner.hide();
        return;
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Booking Failed',
        html: 'Something went wrong while booking. Refresh your browser and try again.',
      });
      this.spinner.hide();
    }
  }
}
