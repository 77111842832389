import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';

import { TimeslotGenerator } from '../shared/helpers/timeslot_generator';

@Component({
  selector: 'app-booking-page',
  templateUrl: './booking-page.component.html',
  styleUrls: ['./booking-page.component.scss'],
})
export class BookingPageComponent implements OnInit {
  timeslotGenerator = new TimeslotGenerator();

  @ViewChild('calendar') calendar: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    plugins: [timeGridPlugin],
    eventClick: this.handleDateClick.bind(this),
    height: '100vh',
    weekends: false,
    slotMinTime: '8:00:00',
    slotMaxTime: '17:15:00',
    slotDuration: '00:15:00',
    locale: 'hu-HU',
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      meridiem: false,
    },
    slotEventOverlap: false,
    events: [
      {
        title: 'RESERVED',
        start: '2023-03-30 12:00',
        end: '2023-03-30 13:30',
        color: '#f54966',
      },
      {
        title: 'RESERVED',
        start: '2023-03-30 15:30',
        end: '2023-03-30 16:30',
        color: '#f54966',
      },
    ],
  };

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    let start = this.calendar.getApi().getCurrentData().dateProfile
      .activeRange.start;
    let end = this.calendar.getApi().getCurrentData().dateProfile
      .activeRange.end;
    let weekDays = this.timeslotGenerator.getDatesBetween(start, end);

    for (var day of weekDays) {
      let times = this.timeslotGenerator.generateTimeSlots(day, 8, 17, [
        ['12:00', '13:30'],
        ['15:30', '16:30'],
      ]);
      for (var time of times) {
        this.calendar
          .getApi()
          .addEvent({
            title: 'FREE',
            start: day + ' ' + time.start,
            end: day + ' ' + time.end,
            color: '#49f577',
          });
      }
    }
  }

  handleDateClick(arg) {
    this.router.navigate(['booking-confirm'], {
      queryParams: {
        datetime: arg.event.startStr,
      },
    });
  }
}
