<body>
    <!-- ====== Header Start ====== -->
    <header class="ud-header">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="index.html">
                            <!-- <img src="../assets/images/logo/ordo_logo_white.svg" alt="Logo" /> -->
                            <h4 style="color: white; font-size: 34px; font-weight: 900;">ReddiDog</h4>
                        </a>
                        <button class="navbar-toggler">
                            <span class="toggler-icon"> </span>
                            <span class="toggler-icon"> </span>
                            <span class="toggler-icon"> </span>
                        </button>

                        <div class="navbar-collapse">
                            <ul id="nav" class="navbar-nav mx-auto">
                                <!-- <li class="nav-item">
                        <a class="ud-menu-scroll" href="#home">Home</a>
                      </li> -->

                                <li class="nav-item">
                                    <a class="ud-menu-scroll" href="/#features">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="ud-menu-scroll" href="/#features">Services</a>
                                </li>
                                <li class="nav-item">
                                    <a class="ud-menu-scroll" href="/#pricing">Prices</a>
                                </li>
                                <li class="nav-item">
                                    <a class="ud-menu-scroll" href="/#contact">Contact</a>
                                </li>
                            </ul>
                        </div>

                        <div class="navbar-btn d-none d-sm-inline-block">
                            <a href="/booking" class="ud-main-btn ud-white-btn">
                                Book An Appointment
                            </a>
                            <!-- <a class="ud-main-btn ud-login-btn" href="javascript:void(0)">
                      Regisztrálás
                    </a> -->
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- ====== Header End ====== -->

    <!-- ====== Banner Start ====== -->
    <section class="ud-page-banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ud-banner-content">
                        <h1>Email sent successfully</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ====== Banner End ====== -->

    <!-- ====== Error 404 Start ====== -->
    <section class="ud-404">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ud-404-wrapper">
                        <div class="ud-404-content">
                            <h2 class="ud-404-title">Köszönjük, hogy érdeklődik termékünk iránt</h2>
                            <h5 class="ud-404-subtitle">
                                Hamarosan felvesszük Önnel a kapcsolatot
                            </h5>
                            <ul class="ud-404-links">
                                <li>
                                    <a href="index.html">Home</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ====== Error 404 End ====== -->

    <!-- ====== Footer Start ====== -->
    <!-- <footer class="ud-footer wow fadeInUp" data-wow-delay=".15s">
        <div class="shape shape-1">
            <img src="assets/images/footer/shape-1.svg" alt="shape" />
        </div>
        <div class="shape shape-2">
            <img src="assets/images/footer/shape-2.svg" alt="shape" />
        </div>
        <div class="shape shape-3">
            <img src="assets/images/footer/shape-3.svg" alt="shape" />
        </div>
        <div class="ud-footer-widgets">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-4 col-md-6">
                        <div class="ud-widget">
                            <a href="index.html" class="ud-footer-logo">
                                <img src="assets/images/logo/logo.svg" alt="logo" />
                            </a>
                            <p class="ud-widget-desc">
                                We create digital experiences for brands and companies by
                                using technology.
                            </p>
                            <ul class="ud-widget-socials">
                                <li>
                                    <a href="https://twitter.com/MusharofChy">
                                        <i class="lni lni-facebook-filled"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/MusharofChy">
                                        <i class="lni lni-twitter-filled"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/MusharofChy">
                                        <i class="lni lni-instagram-filled"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/MusharofChy">
                                        <i class="lni lni-linkedin-original"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                        <div class="ud-widget">
                            <h5 class="ud-widget-title">About Us</h5>
                            <ul class="ud-widget-links">
                                <li>
                                    <a href="javascript:void(0)">Home</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Features</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">About</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Testimonial</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                        <div class="ud-widget">
                            <h5 class="ud-widget-title">Features</h5>
                            <ul class="ud-widget-links">
                                <li>
                                    <a href="javascript:void(0)">How it works</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Privacy policy</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Terms of service</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">Refund policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                        <div class="ud-widget">
                            <h5 class="ud-widget-title">Our Products</h5>
                            <ul class="ud-widget-links">
                                <li>
                                    <a href="https://lineicons.com/" rel="nofollow noopner" target="_blank">Lineicons
                                    </a>
                                </li>
                                <li>
                                    <a href="https://ecommercehtml.com/" rel="nofollow noopner"
                                        target="_blank">Ecommerce HTML</a>
                                </li>
                                <li>
                                    <a href="https://ayroui.com/" rel="nofollow noopner" target="_blank">Ayro UI</a>
                                </li>
                                <li>
                                    <a href="https://graygrids.com/" rel="nofollow noopner" target="_blank">Plain
                                        Admin</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-6 col-md-8 col-sm-10">
                        <div class="ud-widget">
                            <h5 class="ud-widget-title">Partners</h5>
                            <ul class="ud-widget-brands">
                                <li>
                                    <a href="https://ayroui.com/" rel="nofollow noopner" target="_blank">
                                        <img src="assets/images/footer/brands/ayroui.svg" alt="ayroui" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://ecommercehtml.com/" rel="nofollow noopner" target="_blank">
                                        <img src="assets/images/footer/brands/ecommerce-html.svg"
                                            alt="ecommerce-html" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://graygrids.com/" rel="nofollow noopner" target="_blank">
                                        <img src="assets/images/footer/brands/graygrids.svg" alt="graygrids" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://lineicons.com/" rel="nofollow noopner" target="_blank">
                                        <img src="assets/images/footer/brands/lineicons.svg" alt="lineicons" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://uideck.com/" rel="nofollow noopner" target="_blank">
                                        <img src="assets/images/footer/brands/uideck.svg" alt="uideck" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://tailwindtemplates.co/" rel="nofollow noopner" target="_blank">
                                        <img src="assets/images/footer/brands/tailwindtemplates.svg"
                                            alt="tailwindtemplates" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="ud-footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <ul class="ud-footer-bottom-left">
                            <li>
                                <a href="javascript:void(0)">Privacy policy</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">Support policy</a>
                            </li>
                            <li>
                                <a href="javascript:void(0)">Terms of service</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <p class="ud-footer-bottom-right">
                            Designed and Developed by
                            <a href="https://uideck.com" rel="nofollow">UIdeck</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer> -->
    <!-- ====== Footer End ====== -->

    <!-- ====== Back To Top Start ====== -->
    <a href="javascript:void(0)" class="back-to-top">
        <i class="lni lni-chevron-up"> </i>
    </a>
    <!-- ====== Back To Top End ====== -->

    <!-- ====== All Javascript Files ====== -->
    <script src="assets/js/bootstrap.bundle.min.js"></script>
    <script src="assets/js/wow.min.js"></script>
    <script src="assets/js/main.js"></script>
</body>