import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { AppointmentPageComponent } from './appointment-page/appointment-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeaveManagerPageComponent } from './leave-manager-page/leave-manager-page.component';


@NgModule({
  declarations: [
    DashboardComponent,
    AppointmentPageComponent,
    LeaveManagerPageComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class DashboardModule { }
