import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import { EmailTemplate } from '../shared/email-template';
import { formatDate } from '@angular/common';
import { Price } from '../shared/models/shared.models';
import { MockData } from '../shared/data';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  name: string;
  email: string;
  phone: string;
  message: string;

  prices: Price[] = new MockData().prices;

  constructor(private firestore: AngularFirestore, private router: Router) {}

  // comment 4

  async cancelAppointmentClick() {
    var bookingId;
    var email;
    let res = await Swal.fire({
      title: 'Cancel your appointment',
      html: `
      <div>
        <div style="text-align: start; margin-left: 10px;">
          <div style="font-size: 18px; text-align: center;">
            To cancel your appointment, write your booking id and email address below!
          </div>
          <div style="margin-top: 15px;">
            Booking ID
          </div>
          <div style="margin-top: 5px;">
            <input type="text" id="booking-id" style="height: 40px; width: 100%;">
          </div>
          <div style="margin-top: 5px;">
            Email Address
          </div>
          <div style="margin-top: 5px;">
            <input type="text" id="email" style="height: 40px; width: 100%;">
          </div>
        </div>
      </div>`,
      confirmButtonText: 'Send',
      preConfirm: () => [
        (bookingId = (document.querySelector('#booking-id') as HTMLInputElement)
          .value),
        (email = (document.querySelector('#email') as HTMLInputElement).value),
      ],
    });
    if (!res.isConfirmed) return;

    try {
      let bookingRef = await this.firestore
        .collection('bookings')
        .doc(bookingId)
        .get()
        .toPromise();
      let booking = bookingRef.data();
      if (booking['email'] != email) {
        Swal.fire('Oops', 'Booking ID and email pair do not match!', 'error');
        return;
      }
      await this.firestore.collection('bookings').doc(bookingId).delete();
      Swal.fire(
        'Success',
        'Your appointment has been cancelled successfully!',
        'success'
      );

      await this.firestore.collection('mail').add({
        to: email,
        message: {
          subject: 'Appointment Cancellation Confirmation',
          html: new EmailTemplate().getCancelByClientEmail(
            booking['name'],
            formatDate(booking['date'].toDate(), 'dd/MM/yyyy h:mm a', 'en')
          ),
        },
      });
      await this.firestore.collection('mail').add({
        to: 'reddidoggroomer@gmail.com',
        message: {
          subject: 'Lemondott foglalás',
          html: new EmailTemplate().getCancelByClientEmail(
            booking['name'],
            formatDate(booking['date'].toDate(), 'dd/MM/yyyy h:mm a', 'en')
          ),
        },
      });
    } catch (e) {
      Swal.fire('Oops', 'Booking ID is incorrect');
    }
  }

  async sendClick() {
    if (this.name == null || this.name == '') {
      Swal.fire('Oops', 'Name is required!', 'error');
      return;
    }
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (
      this.email == null ||
      this.email == '' ||
      !expression.test(this.email)
    ) {
      Swal.fire('Oops', 'Valid email is required!', 'error');
      return;
    }
    if (this.message == null || this.message == '') {
      Swal.fire('Oops', 'Message is required!', 'error');
      return;
    }

    await this.firestore.collection('mail').add({
      to: this.email,
      message: {
        subject: 'Thank You for Contacting ReddiDOG',
        html: new EmailTemplate().getContactingUsEmail(this.name),
      },
    });

    await this.firestore.collection('mail').add({
      to: 'reddidoggroomer@gmail.com',
      message: {
        subject: 'Új megkeresés',
        html: new EmailTemplate().getContactingUsAdminEmail(
          this.name,
          this.email,
          this.phone,
          this.message
        ),
      },
    });

    Swal.fire(
      'Success',
      'We have sent an email regarding the request, if you have not received it, please check that you have entered the correct email address.',
      'success'
    );
  }

  onBookingClick() {
    this.router.navigate(['booking-confirm']);
  }
}
