import { Timeslot } from '../models/booking.models';

export class TimeslotGenerator {

    public generateTimeSlots(
        day: string,
        startHour: number,
        endHour: number,
        excludedTimes: Array<[string, string]>
    ): Timeslot[] {
        const timeSlots = [];

        const start = new Date(day);
        start.setHours(startHour, 0, 0, 0);
        const end = new Date(day);
        end.setHours(endHour, 0, 0, 0);

        const excludedRanges = excludedTimes.map(([excludedStart, excludedEnd]) => {
            const [startHour, startMinute] = excludedStart.split(':').map((n) => parseInt(n, 10));
            const [endHour, endMinute] = excludedEnd.split(':').map((n) => parseInt(n, 10));
            const start = new Date(day);
            start.setHours(startHour, startMinute, 0, 0);
            const end = new Date(day);
            end.setHours(endHour, endMinute, 0, 0);
            return [start.getTime(), end.getTime()];
        });

        while (start < end) {
            const slotStart = start.getTime();
            const slotEnd = slotStart + 30 * 60 * 1000;
            const slot: {} = { start: new Date(slotStart).toLocaleTimeString(), end: new Date(slotEnd).toLocaleTimeString() };

            if (!excludedRanges.some(([excludedStart, excludedEnd]) => slotStart >= excludedStart && slotEnd <= excludedEnd)) {
                timeSlots.push(slot);
            }

            start.setTime(slotEnd);
        }

        return timeSlots;
    }

    public getDatesBetween(startDate: Date, endDate: Date): string[] {
        const dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(currentDate.toISOString().slice(0, 10));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }
}