import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SendMailService {

    constructor(private http: HttpClient) { }

    performGetEx(): Observable<any> {
        return this.http.get<any>('../assets/php/mail.php');
    }
}