<div class="login-form">
    <form>
        <h1>Login</h1>
        <div class="content">
            <div class="input-field">
                <input type="email" placeholder="Email" autocomplete="nope" [(ngModel)]="email" name="email">
            </div>
            <div class="input-field">
                <input type="password" placeholder="Password" autocomplete="new-password" [(ngModel)]="password"
                    name="password">
            </div>
            <!-- <a href="#" class="link">Forgot Your Password?</a> -->
        </div>
        <div class="action">
            <!-- <button>Register</button> -->
            <button (click)="signInClick()">Sign in</button>
        </div>
    </form>
</div>