<div style="margin: 10px;">
    <div style="margin-bottom: 20px; display: flex; flex-direction: row; justify-content: space-between;">
        <h4>Szabadnapok kezelése</h4>
        <input type="date" [ngModel]="selectedDate | date:'yyyy-MM-dd'" (ngModelChange)="onDateChanged($event)">
        <div style="width: 250px;"></div>
    </div>

    <div class="row mt-2" *ngIf="timeslots != null">
        <div class="col-lg-1 col-4 timeslot-container" *ngFor="let slot of timeslots">
            <div *ngIf="!slot.booked" class="available-timeslot"
                [ngClass]="slot.selected ? 'available-timeslot-selected' : 'available-timeslot'"
                (click)="selectTimeslot(slot)">{{slot.time}}</div>
            <div *ngIf="slot.booked" class="available-timeslot-booked">{{slot.time}}</div>
        </div>
    </div>

    <div style="margin-top: 25px; display: flex; flex-direction: row; justify-content: center;">
        <input type="checkbox" id="wholeday" name="wholeday" [(ngModel)]="wholeDay" (change)="wholeDayChanged()">
        <label for="wholeday" style="margin-left: 10px;"> Egész nap nem vagyok</label><br>
    </div>

    <div class="col-lg-12" style="display: flex; flex-direction: row; justify-content: center; margin-top: 100px;">
        <div class="save-btn" (click)="saveClick()">
            Mentés
        </div>
    </div>

</div>