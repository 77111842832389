<div style="margin: 10px;">
    <div style="margin-bottom: 20px; display: flex; flex-direction: row; justify-content: space-between;">
        <h4>Foglalások</h4>
        <input type="month" [ngModel]="selectedDate | date:'yyyy-MM'" (ngModelChange)="onDateChanged($event)">
        <div class="desktop-only" style="width: 150px;"></div>
    </div>

    <div *ngIf="appointments.length == 0">
        Erre a napra nincs foglalás
    </div>

    <div *ngFor="let appointment of appointments">

        <div class="appointment-item-mobile mobile-only">
            <div style="font-weight: 700;">
                {{appointment.date.toDate() | date: 'yyyy-MM-dd HH:mm'}}
            </div>
            <div style="margin-top: 10px; font-weight: 700;">
                {{appointment.dogType}} - {{appointment.service}}
            </div>
            <div style="margin-top: 5px; font-size: 14px; color: rgb(164, 164, 164);">
                {{appointment.name}}, {{appointment.email}}, {{appointment.phone}}
            </div>
            <hr>
            <div style="font-weight: 700;">
                £ {{appointment.price}}
            </div>
            <div style="font-size: 14px; color: rgb(164, 164, 164);">
                {{appointment.address}}
            </div>
            <hr>
            <div style="font-weight: 700;">
                {{appointment.dogName}}
            </div>
            <div style="font-size: 14px; color: rgb(164, 164, 164);">
                {{appointment.dogBreed}}, {{appointment.dogAge}} éves
            </div>
            <hr>
            <div style="font-weight: 700;">
                Fürdetés
            </div>
            <div style="font-size: 14px; color: rgb(164, 164, 164);">
                {{appointment.withBath ? 'IGEN' : 'NEM'}}
            </div>
            <div class="delete-btn" style="margin-top: 10px;" (click)="cancelClick(appointment)">
                Lemondás
            </div>
        </div>

        <div class="appointment-item-desktop desktop-only">
            <div>
                <div style="font-weight: 700;">
                    {{appointment.date.toDate() | date: 'yyyy-MM-dd'}}
                </div>
                <div style="font-size: 14px; color: rgb(164, 164, 164);">
                    {{appointment.date.toDate() | date: 'HH:mm'}}
                </div>
            </div>
            <div>
                <div style="font-weight: 700;">
                    {{appointment.dogType}} - {{appointment.service}}
                </div>
                <div style="font-size: 14px; color: rgb(164, 164, 164);">
                    {{appointment.name}}, {{appointment.email}}, {{appointment.phone}}
                </div>
            </div>
            <div>
                <div style="font-weight: 700;">
                    £ {{appointment.price}}
                </div>
                <div style="font-size: 14px; color: rgb(164, 164, 164);">
                    {{appointment.inSalon ? 'Szalonban' : appointment.postcode}}, {{appointment.inSalon ? '' :
                    appointment.address}}
                </div>
            </div>
            <div>
                <div style="font-weight: 700;">
                    {{appointment.dogName}}
                </div>
                <div style="font-size: 14px; color: rgb(164, 164, 164);">
                    {{appointment.dogBreed}}, {{appointment.dogAge}} éves
                </div>
            </div>
            <div>
                <div style="font-weight: 700;">
                    Fürdetés
                </div>
                <div style="font-size: 14px; color: rgb(164, 164, 164);">
                    {{appointment.withBath ? 'IGEN' : 'NEM'}}
                </div>
            </div>
            <div>
                <div class="delete-btn" (click)="cancelClick(appointment)">
                    Lemondás
                </div>
            </div>
        </div>

    </div>
</div>