import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingConfirmPageComponent } from './booking-confirm-page/booking-confirm-page.component';
import { BookingPageComponent } from './booking-page/booking-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { TermsAndServicesPageComponent } from './terms-and-services-page/terms-and-services-page.component';
import { BookingVerifiedPageComponent } from './booking-verified-page/booking-verified-page.component';

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent
  },
  {
    path: "booking",
    component: BookingPageComponent
  },
  {
    path: "booking-confirm",
    component: BookingConfirmPageComponent
  },
  {
    path: "thank-you",
    component: ThankYouPageComponent
  },
  {
    path: "auth",
    component: AuthPageComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: "terms-and-services",
    component: TermsAndServicesPageComponent
  },
  {
    path: "booking-verified/:id",
    component: BookingVerifiedPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
