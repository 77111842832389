<header class="header">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
            <a href="#">
                <img src="../assets/images/logo_color.svg" alt="Logo" height="70" />
            </a>
        </div>
    </nav>
</header>

<div class="main-container">
    <div class="container-text">
        <h1>Terms and Conditions</h1>

        <h2>Appointment Scheduling</h2>
        <p>Our online booking service allows you to schedule appointments for your pet grooming needs at our Redditch
            location. Please provide accurate information when booking to ensure that we can provide the best possible
            service.</p>

        <h2>Cancellation Policy</h2>
        <p>If you need to cancel or reschedule your appointment, please notify us at least 24 hours in advance. Failure
            to
            do so may result in a cancellation fee.</p>

        <h2>Payment</h2>
        <p>Payment for our grooming services is due at the time of the appointment. We accept cash, credit cards, and
            debit
            cards. Additional charges may be incurred due to the wear on equipment, additional products used, as well as
            time and skill needed to remove matting.</p>

        <h2>Late Arrival</h2>
        <p>If you are running late for your appointment, please notify us as soon as possible. If you arrive more than
            15
            minutes late, we may need to reschedule your appointment.</p>

        <h2>Vaccinations</h2>
        <p>By using our services, owners confirm that their dog is up to date with their annual vaccinations against
            Canine Distemper, Infectious Canine Hepatitis, Leptospirosis, and Canine Parvovirus. An unvaccinated dog may
            put itself and others at risk. Reddidog Dog Grooming takes no responsibility for any dog that may contract
            any of the aforementioned diseases.
            Reddidog Dog Grooming may ask for proof of vaccination and veterinary information which must be provided.
        </p>

        <h2>Fleas & Ticks</h2>
        <p>Reddidog Dog Grooming is proud to be a tick/flea free environment. If your dog is known to have a parasitic
            infestation, we ask that your grooming appointment is rescheduled to a time when your pet is free from said
            infestation. 48 hours notice must be provided to avoid a cancelation charge. We recommend that you contact
            your veterinary surgeon to provide you with the best recommended treatment. If fleas are found on your dog,
            we will use a flea shampoo and be required to do additional disinfection of our premises and equipment. An
            additional £15 will be charged to your bill. If ticks are found an additional £5 will be charged to include
            removal. We will not be held responsible for any infection caused by the tick or removal thereof.</p>

        <h2>Coat Condition</h2>
        <p>Reddidog Dog Grooming practice humanity over vanity. If we are presented with a neglected coat and believe,
            in our professional opinion, it is kinder to remove the coat than it is to de-mat this will be done in
            accordance with The Animal Welfare Act 2006. Whilst we will make every effort to identify this as part of
            the pre-groom consultation and coat assessment there may be occasions where the degree of matting cannot be
            fully identified until we commence the groom.
            In this event we will make every effort to contact you however if this is not possible we will, in our
            professional opinion, do what we believe is in the best interest of the welfare of the dog. By agreeing to
            these Terms and Conditions you are expressly agreeing to the following De-Matting disclaimer: De-Matting
            Disclaimer
            Under the Animal Welfare Act 2006, it is an offence to cause unnecessary suffering to an animal: – this
            includes prolonged ‘de-matting’ which is painful and detrimental to the welfare of the dog. If you bring in
            your dog with more than 15 minutes of de-matting work, we are required by the Act to clip your dog short
            humanely or refer you to your vet to do the same. We will only attempt to de-matt 5% of a dog – which
            equates to about 50p sized mat behind each ear, same size in the armpits and perhaps one or two in the groin
            or tail area. We have assessed your dog’s coat and found it to be matted, therefore it needs to be clipped
            short. This can be prevented by daily brushing with a slicker brush and comb. Will be happy to explain the
            correct way to brush your dog and suggest a grooming programme that will allow us to achieve the look you
            would like for your dog, this may involve more frequent visits to our salon and will certainly involve daily
            grooming at home. When a dog’s coat becomes matted, it causes discomfort as the matts pull tightly on the
            skin and underlying health conditions may not be visible. When a matted coat is clipped short, it may
            occasional reveal sore, reddened and irritated skin, this is due to the fact that the skin has not been able
            to breathe, and air circulation is not restored. Clipping a matted coat can take longer and cause additional
            wear and tear on our equipment so a further fee may be chargeable in addition to the normal groom price.
            The process of de-matting increases the risk of injury including but not limited to cuts and grazes, skin
            irritations caused by the shortness of the clipper blades that will be used as well as possible haematomas.
            In addition, you may see some behavioural changes such as head shaking, excessive scratching, licking and
            “scooting”. This is where the dog will scrape their back end on the floor to relive itching and irritations.
            Should you have any concerns you should seek advise from your usual vet.</p>

        <h2>Pet Health</h2>
        <p>It is important that your pet is in good health before their grooming appointment. If your pet is sick,
            please
            reschedule their appointment for a later date. We reserve the right to refuse service to pets that show
            signs of
            illness or aggression.</p>

        <h2>Behavioural Charges
            Aggression</h2>
        <p>Owners have the obligation to inform Reddidog Dog Grooming of any known aggressive behaviour traits their pet
            may have to other animals or humans. Specifically, the owner must inform Reddidog Dog Grooming if they have
            been advised by pervious groomer(s) of any of the aforementioned behaviours.</p>


        <h2>Nervous/Anxious Dogs</h2>
        <p>Although Reddidog Dog Grooming caters to dogs with nervous traits, owners have the obligation to inform us of
            any such behaviours in order for us to cater to their needs to the best of our ability.<br>Behavioural
            charges will be decided by Reddidog Dog Grooming depending on the severity of the behaviour and the time,
            skill, and equipment required to groom the dog. If your dog should bite you agree to be responsible for all
            related medical bills, loss of earnings and equipment damage. We reserve the right to refuse service if we
            believe your dog may become a danger to itself or those within the salon. In the event any of these
            behaviours present during the groom the session may stopped at anytime and the full fee
            incurred.<br><br>Please toilet your dog before attending their appointment. Fouling in the salon may result
            in additional charges.</p>

        <h2>Pricing</h2>
        <p>Any prices provided are an estimate only. Additional charges may be incurred due to:</p>
        <ul>
            <li>- Overweight dogs or oversized for the breed</li>
            <li>- Unkept/neglected coats</li>
            <li>- Aggressive, senior, nervous or timid dogs that require additional handling</li>
            <li>- Fleas and/or ticks</li>
            <li>- Dogs that require additional handling for whatever reason</li>
        </ul>

        <h2>GDPR Statement</h2>
        <p>In order for us to provide the requested services, we are required to obtain and store a limited amount of
            your personal information. Such information will be stored in accordance with all relevant legislation, will
            never be shared with any 3rd parties or organisations and will only be used for the purposes of providing
            the requested or related services. You have the right to request and review any of your personal
            information. Please make any requests to reddidoggrooming@gmail.com.</p>


        <h2>Liability</h2>
        <p>We take great care when grooming your pet, but accidents can happen. We are not responsible for any injuries
            or
            health issues that may arise during or after the grooming session.</p>

        <h2>Data Privacy</h2>
        <p>We take your data privacy seriously and will only use your personal information for the purpose of scheduling
            your grooming appointment. We will not share your information with third parties.</p>
    </div>
</div>