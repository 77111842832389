import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookingPageComponent } from './booking-page/booking-page.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { BookingConfirmPageComponent } from './booking-confirm-page/booking-confirm-page.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SendMailService } from './shared/services/send-mail.service';
import { HttpClientModule } from '@angular/common/http';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { AuthGuard } from './shared/guards/auth-guard.service';
import { AuthService } from './shared/services/auth.service';
import { TermsAndServicesPageComponent } from './terms-and-services-page/terms-and-services-page.component';
import { BookingVerifiedPageComponent } from './booking-verified-page/booking-verified-page.component';

@NgModule({
  declarations: [
    AppComponent,
    BookingPageComponent,
    LandingPageComponent,
    BookingConfirmPageComponent,
    ThankYouPageComponent,
    AuthPageComponent,
    TermsAndServicesPageComponent,
    BookingVerifiedPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SweetAlert2Module.forRoot(),
    HttpClientModule,
    AngularMyDatePickerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    DashboardModule
  ],
  providers: [
    SendMailService,
    AuthService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
