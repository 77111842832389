export class EmailTemplate {
    getSuccessEmail(
        name: string,
        date: string,
        address: string,
        postcode: string,
        price: number,
        dogType: string,
        service: string,
        id: string,
        duration: string,
        dogName: string,
        dogAge: string,
        dogBreed: string,
        withBath: boolean,
        inSalon: boolean): string {
        var email = `
        <div>
            <div style="margin-top: 25px;">
                Hello ${name}!
            </div>

            <a style="margin-top: 25px; margin-bottom: 25px; font-size: 26px; font-weight: 900; text-decoration: underline;" href="reddidog.co.uk/booking-verified/${id}">
                Click here to finalize your booking
            </a>

            <div style="margin-top: 25px;">
                We have processed your booking on our webpage! Check out the details. Feel free to write to <i>reddidoggroomer@gmail.com</i>     if you have any questions or requests.<br>Here are the details:
            </div>
            <div style="margin-top: 25px; margin-bottom: 25px;">
                <b>Date: </b>${date}<br>
                <b>Address: </b>${inSalon ? '499 Evesham Road Crabbs  Cross, Redditch, Worcestershire B97 5JJ. Inside Paws & Whiskers pet grooming spa' : address}<br>
                ${inSalon ? '' : `<b>Postcode: </b>${postcode}<br>`}
                <b>Dog Type: </b>${dogType}<br>
                <b>Service: </b>${service}<br>
                <b>Estimated Duration: </b>${duration} hours<br><br>
                <b>Dog's Name: </b>${dogName}<br>
                <b>Dog's Age: </b>${dogAge}<br>
                <b>Dog's Breed: </b>${dogBreed}<br>
                <b>Bath included: </b>${withBath ? 'Yes' : 'No'}<br>
                <b>Total Price: £ </b>${price}<br><br>
                <b>Booking ID: </b>${id}<br>
            </div>

            <div style="margin-top: 25px;">
                Best Regards,<br>
                ReddiDOG
            </div>
        </div>
        `;

        return email;
    }

    getSuccessEmailForAdmin(
        name: string,
        date: string,
        address: string,
        postcode: string,
        price: number,
        dogType: string,
        service: string,
        duration: string,
        dogName: string,
        dogAge: string,
        dogBreed: string,
        withBath: boolean,
        inSalon: boolean
    ): string {
        var email = `
        <div>
            <div style="text-align: center; font-weight: 900; font-size: 28px;">
                Új foglalás érkezett
            </div>
            <div style="margin-top: 25px; margin-bottom: 25px;">
                <b>Név: </b>${name}<br>
                <b>Dátum: </b>${date}<br>
                <b>Szalonban: </b>${inSalon ? 'Igen' : 'Nem'}<br>
                ${inSalon ? '' : `<b>Cím: </b>${address}<br>`}
                ${inSalon ? '' : `<b>Postcode: </b>${postcode}<br>`}
                <b>Kutya típus: </b>${dogType}<br>
                <b>Szolgáltatás: </b>${service}<br>
                <b>Várható időtartam: </b>${duration} hours<br><br>
                <b>Kutya neve: </b>${dogName}<br>
                <b>Kutya kora: </b>${dogAge}<br>
                <b>Kutya fajtája: </b>${dogBreed}<br>
                <b>Fürdetés: </b>${withBath ? 'Igen' : 'Nem'}<br>
                <b>Ár: £ </b>${price}<br><br>
            </div>
        </div>
        `;

        return email;
    }

    getCancelEmail(reason: string, name: string, date: string) {
        var email = `
        <div>
            <div style="text-align: center; font-weight: 900; font-size: 28px;">
                Appointment Cancellation
            </div>
            
            <div style="margin-top: 25px;">
                Dear ${name},
            </div>
            <div style="margin-top: 25px;">
                I regret to inform you that we have to cancel the appointment we had scheduled for ${date}. Unfortunately, due to unforeseen circumstances, we are unable to accommodate your appointment at this time.
            </div>
            <div style="margin-top: 25px;">
                I understand that this may be an inconvenience for you, and I apologize for any disruption this may cause to your schedule.
            </div>
            <div style="margin-top: 25px;">
                Please let us know if you have any questions or concerns, and we will be more than happy to assist you in any way we can. We appreciate your understanding in this matter and hope to have the opportunity to serve you in the future.
            </div>
            <div style="margin-top: 25px;">
                Thank you for your patience and cooperation.
            </div>
            <div style="margin-top: 25px;">
                Best Regards,<br>
                ReddiDOG
            </div>
        </div>
        `;

        return email;
    }

    getCancelByClientEmail(name: string, date: string) {
        var email = `
        <div>
            <div style="text-align: center; font-weight: 900; font-size: 28px;">
                Appointment Cancellation Confirmation
            </div>
            
            <div style="margin-top: 25px;">
                Dear ${name},
            </div>
            <div style="margin-top: 25px;">
                I am writing to confirm that we have received your request to cancel the appointment scheduled for ${date}. We are sorry to hear that you will not be able to make it, but we appreciate your letting us know in advance.
            </div>
            <div style="margin-top: 25px;">
                We have canceled the appointment as per your request, and you will not be charged any cancellation fees. Please note that if you would like to reschedule the appointment, we will be more than happy to assist you in finding a suitable time and date.
            </div>
            <div style="margin-top: 25px;">
                Thank you for choosing our services, and we hope to have the opportunity to serve you in the future. If you have any questions or concerns, please do not hesitate to contact us.
            </div>
            <div style="margin-top: 25px;">
                Best Regards,<br>
                ReddiDOG
            </div>
        </div>
        `;

        return email;
    }

    getContactingUsEmail(name: string) {
        var email = `
        <div>
            <div style="text-align: center; font-weight: 900; font-size: 28px;">
                Thank You for Contacting ReddiDOG
            </div>
            
            <div style="margin-top: 25px;">
                Dear ${name},
            </div>
            <div style="margin-top: 25px;">
                Thank you for reaching out to us! We're thrilled that you're interested in our services. Our team has received your message and we're working on getting back to you as soon as possible. We appreciate your patience as we carefully review your request.
            </div>
            <div style="margin-top: 25px;">
                At ReddiDOG, we're committed to providing excellent service and ensuring your experience with us is a positive one. We're confident that our services will meet and exceed your expectations, and we can't wait to show you what we can do.
            </div>
            <div style="margin-top: 25px;">
                In the meantime, feel free to browse our website and social media channels to learn more about what we offer. If you have any further questions or concerns, please don't hesitate to reach out to us. We're always here to help.
            </div>
            <div style="margin-top: 25px;">
                Thank you again for considering ReddiDOG. We look forward to connecting with you soon!
            </div>
            <div style="margin-top: 25px;">
                Best Regards,<br>
                ReddiDOG
            </div>
        </div>
        `;

        return email;
    }

    getContactingUsAdminEmail(name: string, email: string, phone: string, message: string) {
        var email = `
        <div>
            <div style="text-align: center; font-weight: 900; font-size: 28px;">
                Új megkeresés érkezett a weboldalról
            </div>
            
            <div style="margin-top: 25px;">
            <b>Név: </b>${name}<br>
            <b>Email: </b>${email}<br>
            <b>Telefon: </b>${phone}<br>
            <b>Üzenet: </b>${message}<br>
        </div>
        </div>
        `;

        return email;
    }
}