import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let isAuth = localStorage.getItem('reddidogToken');
        if (!isAuth) {
            this.router.navigate(['/auth']);
        }
        else {
            return true;
        }
    }
}