import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmailTemplate } from '../shared/email-template';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-booking-verified-page',
  templateUrl: './booking-verified-page.component.html',
  styleUrls: ['./booking-verified-page.component.scss']
})
export class BookingVerifiedPageComponent implements OnInit {

  bookingId: string;
  status: string;
  data: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.bookingId = params['id'];
      this.getData();
    });
  }

  async getData() {
    this.spinner.show();
    let res = await this.firestore.collection('bookings').doc(this.bookingId).get().toPromise();
    this.data = res.data();
    var endDate = new Date();
    endDate.setMinutes(endDate.getMinutes() + 1);
    try {
      if (this.data['bookedAt'] == endDate && !this.data['emailVerified']) {
        res.ref.delete();
        this.status = "time_over";
        this.spinner.hide();
      }
      if (this.data['emailVerified']) {
        this.status = "already_verified";
        this.spinner.hide();
      } else {
        this.firestore.collection('bookings').doc(this.bookingId).update({ emailVerified: true }).then(() => {
          this.firestore.collection('mail').add({
            to: "reddidoggroomer@gmail.com",
            message: {
              subject: "Új foglalás érkezett",
              html: new EmailTemplate().getSuccessEmailForAdmin(
                this.data["name"],
                formatDate(this.data["date"].toDate(), 'dd/MM/yyyy h:mm a', "en"),
                this.data["address"],
                this.data["postcode"],
                this.data["price"],
                this.data["dogType"],
                this.data["service"],
                this.data["durationHour"],
                this.data["dogName"],
                this.data["dogAge"],
                this.data["dogBreed"],
                this.data["withBath"],
                this.data["inSalon"])
            }
          });
          this.spinner.hide();
          this.status = "success";
        });
      }
    } catch (e) {
      this.status = "error";
      this.spinner.hide();
    }
  }

  okClick() {
    this.router.navigate(["/"]);
  }

}
