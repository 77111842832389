<body>
  <!-- ====== Header Start ====== -->
  <header class="ud-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <nav class="navbar navbar-expand-lg">
            <a href="index.html">
              <img src="../assets/images/logo_white.svg" alt="Logo" height="70" />
              <!-- <h4 style="color: white; font-size: 34px; font-weight: 900;">ReddiDog</h4> -->
            </a>
            <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
              <span class="toggler-icon"></span>
            </button>

            <div class="mobile-only" style="margin: auto;">
              <a href="/booking-confirm" class="ud-main-btn ud-white-btn" style="font-size: 20px;">
                Booking
              </a>
              <a class="ud-main-btn ud-white-btn" style="margin-left: 10px; font-size: 20px;"
                (click)="cancelAppointmentClick()">
                Cancel Booking
              </a>
            </div>

            <div class="collapse navbar-collapse">
              <ul id="nav" class="nav navbar-nav mx-auto">
                <!-- <li class="nav-item">
                    <a class="ud-menu-scroll" href="#home">Home</a>
                  </li> -->

                <li class="nav-item">
                  <a class="ud-menu-scroll" href="#features">Services</a>
                </li>
                <li class="nav-item">
                  <a class="ud-menu-scroll" href="#pricing">Prices</a>
                </li>
                <li class="nav-item">
                  <a class="ud-menu-scroll" href="#gallery">Gallery</a>
                </li>
                <li class="nav-item">
                  <a class="ud-menu-scroll" href="#contact">Contact</a>
                </li>
              </ul>
            </div>

            <div class="navbar-btn d-none d-sm-inline-block">
              <a href="/booking-confirm" class="ud-main-btn ud-white-btn">
                Booking
              </a>
              <a class="ud-main-btn ud-white-btn" style="margin-left: 10px;" (click)="cancelAppointmentClick()">
                Cancel Booking
              </a>
              <!-- <a class="ud-main-btn ud-login-btn" href="javascript:void(0)">
                  Regisztrálás
                </a> -->
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>

  <section class="ud-hero" id="home">
    <div class="container" style="margin-top: 25px;">
      <div class="row">
        <div class="col-lg-12">
          <div class="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
            <h1 class="ud-hero-title">
              Home<br>dog groomer service
            </h1>
            <h4 class="ud-hero-title" style="font-size: 24px; text-decoration: underline; cursor: pointer;"
              (click)="onBookingClick()">
              Book your
              timeslot now</h4>
            <p class="ud-hero-desc">
              Pamper your pet with our grooming services.
            </p>
            <img src="assets/images/hero/dog.png" alt="dog" class="image-hamburger desktop-only" />
            <img src="assets/images/hero/hair-cut-tool.png" alt="groomer" class="image-beer desktop-only" />
          </div>
          <div class="ud-hero-image wow fadeInUp" data-wow-delay=".25s">
            <!-- <img src="assets/images/hero/admin2_half.png" alt="hero-image" /> -->
            <!-- <img src="assets/images/hero/dotted-shape.svg" alt="shape" class="shape shape-1" />
              <img src="assets/images/hero/dotted-shape.svg" alt="shape" class="shape shape-2" /> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Hero End ====== -->

  <!-- ====== Features Start ====== -->
  <section id="features" class="ud-features">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="ud-section-title">
            <span>Services</span>
            <h2>Available services</h2>
            <p>
              Experienced and personalized dog grooming services brought to your home. Book your appointment today.
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <!-- <lottie-player class="ud-benefits-orders-lottie" id="qrLottie" style="height: 250px;"
              src="https://assets7.lottiefiles.com/packages/lf20_he0riwlq.json">
            </lottie-player> -->
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="ud-single-feature wow fadeInUp" data-wow-delay=".1s">
            <div class="ud-feature-icon">
              <i class="lni lni-gift"></i>
            </div>
            <div class="ud-feature-content">
              <h3 class="ud-feature-title">In-Home Pet Grooming.</h3>
              <p class="ud-feature-desc">
                Convenient grooming services in the comfort of your pet's home.
              </p>
              <!-- <a href="javascript:void(0)" class="ud-feature-link">
                  Learn More
                </a> -->
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="ud-single-feature wow fadeInUp" data-wow-delay=".15s">
            <div class="ud-feature-icon">
              <i class="lni lni-mobile"></i>
            </div>
            <div class="ud-feature-content">
              <h3 class="ud-feature-title">Personalized Attention.</h3>
              <p class="ud-feature-desc">
                One-on-one care for a stress-free grooming experience.
              </p>
              <!-- <a href="javascript:void(0)" class="ud-feature-link">
                  Learn More
                </a> -->
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="ud-single-feature wow fadeInUp" data-wow-delay=".2s">
            <div class="ud-feature-icon">
              <i class="lni lni-layout"></i>
            </div>
            <div class="ud-feature-content">
              <h3 class="ud-feature-title">Holistic Grooming.</h3>
              <p class="ud-feature-desc">
                A holistic approach to pet grooming with an emphasis on overall health and well-being.
              </p>
              <!-- <a href="javascript:void(0)" class="ud-feature-link">
                  Learn More
                </a> -->
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6" style="cursor: pointer;" (click)="onBookingClick()">
          <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
            <div class="ud-feature-icon">
              <i class="lni lni-layers"></i>
            </div>
            <div class="ud-feature-content">
              <h3 class="ud-feature-title">Online Booking</h3>
              <p class="ud-feature-desc">
                You can easily make an appointment in our online system
              </p>
              <!-- <a href="javascript:void(0)" class="ud-feature-link">
                  Learn More
                </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Features End ====== -->

  <!-- ====== Guide start ====== -->
  <!-- <section id="guide" class="ud-about">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ud-section-title">
              <span>Ismertető</span>
              <h2>Hogyan működik az ORDO?</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-6">
            <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
              <div class="ud-feature-content">
                <h2 class="ud-feature-title">Digitális menü elkészítése</h2>
                <p class="ud-feature-desc">
                  Tökéletes digitális menü elkészítése és módosítása extrákkal és preferenciákkal. Menü
                  elkészítésben mi is tudunk segíteni az elején.
                </p>
                <div class="ud-feature-image-container">
                  <img class="ud-feature-image img-fixed" src="assets/images/hero/create_menu.png" alt="hero-image" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-6">
            <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
              <div class="ud-feature-content">
                <h2 class="ud-feature-title">Scan, rendelés és fizetés</h2>
                <p class="ud-feature-desc">
                  Az ügyfelek egyszerűen beolvasnak egy QR-kódot vagy NFC-t bármely okostelefon segítségével a menü
                  böngészéséhez, majd a termék
                  megrendeléshez és fizetéshez.
                  <b>Nincs szükség alkalmazás letöltésére.</b>
                </p>
                <div class="ud-feature-image-container">
                  <img class="ud-feature-image img-fixed" src="assets/images/hero/mobile_drawing.png" alt="hero-image" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-6">
            <div class="ud-single-feature wow fadeInUp" data-wow-delay=".25s">
              <div class="ud-feature-content">
                <h2 class="ud-feature-title">Vendégek kiszolgálása</h2>
                <p class="ud-feature-desc">
                  Rendelési és fizetési funkciók széles skálájával – egyetlen egyszerű rendszeren keresztül kezelhetők a
                  rendeléseket, ellenőrízhetők a készleteket. Jelentések, kimutatások készítése lehetséges.
                </p>
                <div class="ud-feature-image-container">
                  <img class="ud-feature-image img-fixed" src="assets/images/hero/live_orders.png" alt="hero-image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  <!-- ====== Guide End ====== -->

  <!-- ====== Benefits Start ====== -->
  <!-- <section id="benefits" class="ud-features">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ud-section-title">
              <span>Előnyök</span>
              <h2>Milyen előnyökkel jár az ORDO használata?</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-sm-6">
            <div class="ud-single-feature wow fadeInUp" data-wow-delay=".1s">
              <div class="ud-feature-content">
                <h3 class="ud-feature-title">Könnyű használat</h3>
                <p class="ud-feature-desc">
                  Böngészőből indítható felületünknek köszönhetően regisztráció után akár 24 órával már elérhető lesz a
                  vendégek számára a program.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6">
            <div class="ud-single-feature wow fadeInUp" data-wow-delay=".1s">
              <div class="ud-feature-content">
                <h3 class="ud-feature-title">Növekvő bevétel</h3>
                <p class="ud-feature-desc">
                  Külföldi példák és saját felméréseink alapján akár 15-20%-os növekedést lehet elérni az applikáció
                  használatával.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6">
            <div class="ud-single-feature wow fadeInUp" data-wow-delay=".1s">
              <div class="ud-feature-content">
                <h3 class="ud-feature-title">Biztonságosabb</h3>
                <p class="ud-feature-desc">
                  Csökkenti a kontaktusok számát, mivel a rendelés és a fizetés is online történik csökken a felszolgálók
                  és vevők érintkezése ami biztonságosabbá és gördülékenyebbé teszi a rendelést.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-sm-6">
            <div class="ud-single-feature wow fadeInUp" data-wow-delay=".1s">
              <div class="ud-feature-content">
                <h3 class="ud-feature-title">Értsd meg a vevőt</h3>
                <p class="ud-feature-desc">
                  Heti és havi kimutatásainknak köszönhetően a vevők igényei még érthetőbbé válnak, és a vendéglátóhely
                  menüjét könnyedén a keresletre lehet szabni.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="align-items: center;">
          <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12">
            <div class="ud-benefits-counter-container">
              <div class="ud-benefits-orders">
                <span style="font-size: 25px; font-weight: bold;">Rendelések</span>
                <lottie-player class="ud-benefits-orders-lottie" id="firstLottie"
                  src="https://uploads-ssl.webflow.com/61124c51302788f198394d2c/61124c51302788f157394d8b_NumCounter1.json">
                </lottie-player>
              </div>
              <div class="ud-benefits-orders">
                  <span style="font-size: 25px; font-weight: bold;">Bevétel</span>
                  <lottie-player class="ud-benefits-orders-lottie" id="revenueLottie"
                    src="https://uploads-ssl.webflow.com/5e5a87a53c3e0f76a5eba371/615c19f30c4d2f25841faaba_NumCounter2.json">
                  </lottie-player>
                </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-1"></div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <lottie-player class="ud-benefits-orders-lottie" id="dashboardLottie"
              src="https://assets2.lottiefiles.com/packages/lf20_2RSPTv.json">
            </lottie-player>
          </div>
        </div>
      </div>
    </section> -->
  <!-- ====== Benefits End ====== -->

  <!-- ====== About Start ====== -->
  <!-- <section id="about" class="ud-about">
      <div class="container">
        <div class="ud-about-wrapper wow fadeInUp" data-wow-delay=".2s">
          <div class="ud-about-content-wrapper">
            <div class="ud-about-content">
              <span class="tag">About Us</span>
              <h2>Brilliant Toolkit to Build Nextgen Website Faster.</h2>
              <p>
                The main ‘thrust’ is to focus on educating attendees on how to
                best protect highly vulnerable business applications with
                interactive panel discussions and roundtables led by subject
                matter experts.
              </p>
  
              <p>
                The main ‘thrust’ is to focus on educating attendees on how to
                best protect highly vulnerable business applications with
                interactive panel.
              </p>
              <a href="javascript:void(0)" class="ud-main-btn">Learn More</a>
            </div>
          </div>
          <div class="ud-about-image">
            <img src="assets/images/about/about-image.svg" alt="about-image" />
          </div>
        </div>
      </div>
    </section> -->
  <!-- ====== About End ====== -->



  <!-- ====== Pricing Start ====== -->
  <section id="pricing" class="ud-pricing">
    <div class="row">
      <div class="col-lg-12">
        <div class="ud-section-title mx-auto text-center">
          <span>Our prices</span>
          <p>
            Grooming (including bathing, drying), Washing and hand drying, Brush out Trimming Clipping & Scissor
            Cutting,
            Hand-stripping, Nails clipping, A health check including cleansing of the ears and eyes, Anal glands
          </p>
        </div>
      </div>
    </div>

    <div class="mobile-only">
      <div class="mobile-price-card" *ngFor="let price of prices">
        <h4>{{price.service}}</h4>
        <div *ngFor="let data of price.data; let i = index">
          <div class="mobile-price-row">
            <h6 style="align-self: center; margin-left: 15px;">{{data.size}}</h6>
            <div>
              <div *ngFor="let d of data.descr">{{d}}</div>
            </div>
            <div style="width: 70px;"></div>
          </div>
          <hr *ngIf="i != price.data.length-1">
        </div>
        <hr>
      </div>
    </div>

    <div class="table-container desktop-only">
      <div class="table-wrapper">
        <div class="table-responsive">
          <table>
            <thead>
              <tr class="title">
                <th></th>
                <th>Short Hair</th>
                <th>Long Hair</th>
                <th>Handstripping</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>X Small<br>1-3 lbs</td>
                <td>Chihuahua<br>Miniature Pinscher<br>£15</td>
                <td>Long Hair Chihuahua<br>X Small Yorkshire Terrier<br>£20</td>
                <td></td>
              </tr>
              <tr>
                <td>Small<br>3-5 lbs</td>
                <td>French Bulldog<br>Pug<br>Jack Russel<br>£20</td>
                <td>Shi-zhu<br>Bichon<br>Yorkshire Terrier<br>Mini Schnauzer<br>£25</td>
                <td>Border Terrier<br>Westie<br>£30</td>
              </tr>
              <tr>
                <td>Medium<br>5-8 lbs</td>
                <td>Straffordshire Bull Terrier<br>Kelpie<br>£25</td>
                <td>Cocker Spaniel<br>Cockapoo<br>£30</td>
                <td>Welsh Terrier<br>£35</td>
              </tr>
              <tr>
                <td>Large<br>8-10 lbs</td>
                <td>Labrador<br>Dalmatian<br>Vizla<br>Boxer<br>£35</td>
                <td>Standard Poodle<br>Border Collie<br>Setter<br>£40</td>
                <td>Golden Retriever<br>Huskey<br>£45</td>
              </tr>
              <tr>
                <td>X Large<br>10+ lbs</td>
                <td>Great Dane<br>Bull Mastiff<br>£45</td>
                <td>Newfoundland<br>£50</td>
                <td>Airedale<br>£55</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div style="text-align: center; margin-top: 25px; font-size: 16px;">
      The service is available in Redditch and its 10-mile radius.<br>
      An additional £5 travel charge applies to customers outside of Redditch.
    </div>
  </section>
  <!-- <section id="pricing" class="ud-pricing">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="ud-section-title mx-auto text-center">
            <span>Our prices</span>
            <p>
              All prices are for a standard groom, prices may vary depending on condition of dog, but this can be
              discussed prior to grooming.
            </p>
          </div>
        </div>
      </div>

      <div class="row g-0 align-items-center justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-10">
          <div class="ud-single-pricing first-item wow fadeInUp" data-wow-delay=".15s">
            <div class="ud-pricing-header">
              <h3>Small</h3>
              <h4>from £ 5</h4>
            </div>
            <div class="ud-pricing-body">
              <ul>
                <li>5 User</li>
                <li>All UI components</li>
                <li>Lifetime access</li>
                <li>Free updates</li>
                <li>Use on 1 (one) project</li>
                <li>4 Months support</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-10">
          <div class="ud-single-pricing first-item wow fadeInUp" data-wow-delay=".15s">
            <div class="ud-pricing-header">
              <h3>Medium</h3>
              <h4>from £ 10</h4>
            </div>
            <div class="ud-pricing-body">
              <ul>
                <li>5 User</li>
                <li>All UI components</li>
                <li>Lifetime access</li>
                <li>Free updates</li>
                <li>Use on 1 (one) project</li>
                <li>4 Months support</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-10">
          <div class="ud-single-pricing last-item wow fadeInUp" data-wow-delay=".15s">
            <div class="ud-pricing-header">
              <h3>Big</h3>
              <h4>from £ 15</h4>
            </div>
            <div class="ud-pricing-body">
              <ul>
                <li>5 User</li>
                <li>All UI components</li>
                <li>Lifetime access</li>
                <li>Free updates</li>
                <li>Use on 1 (one) project</li>
                <li>4 Months support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- ====== Pricing End ====== -->

  <!-- ====== FAQ Start ====== -->
  <!-- <section id="faq" class="ud-faq">
      <div class="shape">
        <img src="assets/images/faq/shape.svg" alt="shape" />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ud-section-title text-center mx-auto">
              <span>FAQ</span>
              <h2>Any Questions? Answered</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="col-lg-6">
            <div class="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
              <div class="accordion">
                <button class="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                  <span class="icon flex-shrink-0">
                    <i class="lni lni-chevron-down"></i>
                  </span>
                  <span>How to use UIdeck?</span>
                </button>
                <div id="collapseOne" class="accordion-collapse collapse">
                  <div class="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </div>
                </div>
              </div>
            </div>
            <div class="ud-single-faq wow fadeInUp" data-wow-delay=".15s">
              <div class="accordion">
                <button class="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                  <span class="icon flex-shrink-0">
                    <i class="lni lni-chevron-down"></i>
                  </span>
                  <span>How to download icons from Lineicons?</span>
                </button>
                <div id="collapseTwo" class="accordion-collapse collapse">
                  <div class="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </div>
                </div>
              </div>
            </div>
            <div class="ud-single-faq wow fadeInUp" data-wow-delay=".2s">
              <div class="accordion">
                <button class="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                  <span class="icon flex-shrink-0">
                    <i class="lni lni-chevron-down"></i>
                  </span>
                  <span>Is GrayGrids part of UIdeck?</span>
                </button>
                <div id="collapseThree" class="accordion-collapse collapse">
                  <div class="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
              <div class="accordion">
                <button class="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                  <span class="icon flex-shrink-0">
                    <i class="lni lni-chevron-down"></i>
                  </span>
                  <span>Can I use this template for commercial project?</span>
                </button>
                <div id="collapseFour" class="accordion-collapse collapse">
                  <div class="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </div>
                </div>
              </div>
            </div>
            <div class="ud-single-faq wow fadeInUp" data-wow-delay=".15s">
              <div class="accordion">
                <button class="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                  <span class="icon flex-shrink-0">
                    <i class="lni lni-chevron-down"></i>
                  </span>
                  <span>Do you have plan releasing Play Pro?</span>
                </button>
                <div id="collapseFive" class="accordion-collapse collapse">
                  <div class="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </div>
                </div>
              </div>
            </div>
            <div class="ud-single-faq wow fadeInUp" data-wow-delay=".2s">
              <div class="accordion">
                <button class="ud-faq-btn collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix">
                  <span class="icon flex-shrink-0">
                    <i class="lni lni-chevron-down"></i>
                  </span>
                  <span>Where and how to host this template?</span>
                </button>
                <div id="collapseSix" class="accordion-collapse collapse">
                  <div class="ud-faq-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  <!-- ====== FAQ End ====== -->

  <!-- ====== Testimonials Start ====== -->
  <section id="testimonials" class="ud-testimonials">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="ud-section-title mx-auto text-center">
            <span>Testimonials</span>
            <h2>What our Customers Say</h2>
            <!-- <p>
              There are many variations of passages of Lorem Ipsum available
              but the majority have suffered alteration in some form.
            </p> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="ud-single-testimonial wow fadeInUp" data-wow-delay=".1s">
            <div class="ud-testimonial-ratings">
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
            </div>
            <div class="ud-testimonial-content">
              <p>
                Thank you for your good and professional service! We really liked the change for Chanel! 🐶🐾😍
              </p>
            </div>
            <div class="ud-testimonial-info">
              <div class="ud-testimonial-image">
                <img src="assets/images/testimonials/author-01.png" alt="author" />
              </div>
              <div class="ud-testimonial-meta">
                <h4>Ross J Tana</h4>
                <!-- <p>Founder @UIdeck</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="ud-single-testimonial wow fadeInUp" data-wow-delay=".15s">
            <div class="ud-testimonial-ratings">
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
            </div>
            <div class="ud-testimonial-content">
              <p>
                Great and professional service. highly recommended 👌
              </p>
            </div>
            <div class="ud-testimonial-info">
              <div class="ud-testimonial-image">
                <img src="assets/images/testimonials/author-02.png" alt="author" />
              </div>
              <div class="ud-testimonial-meta">
                <h4>Vony Sarad</h4>
                <!-- <p>Founder @Lineicons</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="ud-single-testimonial wow fadeInUp" data-wow-delay=".2s">
            <div class="ud-testimonial-ratings">
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
              <i class="lni lni-star-filled"></i>
            </div>
            <div class="ud-testimonial-content">
              <p>
                Gerti has all the must have qualities of a brilliant groomer: warm, pet loving, patient and highly
                skilled. You know you leave your dog in good hands.
              </p>
            </div>
            <div class="ud-testimonial-info">
              <div class="ud-testimonial-image">
                <img src="assets/images/testimonials/author-03.png" alt="author" />
              </div>
              <div class="ud-testimonial-meta">
                <h4>Kinga Gaska</h4>
                <!-- <p>Founder @GrayGrids</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-lg-12">
          <div class="ud-brands wow fadeInUp" data-wow-delay=".2s">
            <div class="ud-title">
              <h6>Trusted and Used by</h6>
            </div>
            <div class="ud-brands-logo">
              <div class="ud-single-logo">
                <img src="assets/images/brands/ayroui.svg" alt="ayroui" />
              </div>
              <div class="ud-single-logo">
                <img src="assets/images/brands/uideck.svg" alt="uideck" />
              </div>
              <div class="ud-single-logo">
                <img src="assets/images/brands/graygrids.svg" alt="graygrids" />
              </div>
              <div class="ud-single-logo">
                <img src="assets/images/brands/lineicons.svg" alt="lineicons" />
              </div>
              <div class="ud-single-logo">
                <img src="assets/images/brands/ecommerce-html.svg" alt="ecommerce-html" />
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </section>
  <!-- ====== Testimonials End ====== -->

  <!-- ====== Team Start ====== -->
  <section id="gallery" class="ud-team">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="ud-section-title mx-auto text-center">
            <span>References</span>
            <h2>Check out some of our previous works</h2>
            <!-- <p>
              There are many variations of passages of Lorem Ipsum available
              but the majority have suffered alteration in some form.
            </p> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="ud-single-team wow fadeInUp" data-wow-delay=".1s">
            <div class="ud-team-image-wrapper">
              <div class="ud-team-image">
                <img src="assets/images/work_refs/2.jpg" alt="team" />
              </div>

              <!-- <img src="assets/images/team/dotted-shape.svg" alt="shape" class="shape shape-1" />
              <img src="assets/images/team/shape-2.svg" alt="shape" class="shape shape-2" /> -->
            </div>
            <!-- <div class="ud-team-info">
              <h5>Adveen Desuza</h5>
              <h6>UI Designer</h6>
            </div>
            <ul class="ud-team-socials">
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-facebook-filled"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-twitter-filled"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-instagram-filled"></i>
                </a>
              </li>
            </ul> -->
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="ud-single-team wow fadeInUp" data-wow-delay=".15s">
            <div class="ud-team-image-wrapper">
              <div class="ud-team-image">
                <img src="assets/images/work_refs/3.jpg" alt="team" />
              </div>

              <!-- <img src="assets/images/team/dotted-shape.svg" alt="shape" class="shape shape-1" />
              <img src="assets/images/team/shape-2.svg" alt="shape" class="shape shape-2" /> -->
            </div>
            <!-- <div class="ud-team-info">
              <h5>Jezmin uniya</h5>
              <h6>Product Designer</h6>
            </div>
            <ul class="ud-team-socials">
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-facebook-filled"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-twitter-filled"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-instagram-filled"></i>
                </a>
              </li>
            </ul> -->
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="ud-single-team wow fadeInUp" data-wow-delay=".2s">
            <div class="ud-team-image-wrapper">
              <div class="ud-team-image">
                <img src="assets/images/work_refs/5.jpg" alt="team" />
              </div>

              <!-- <img src="assets/images/team/dotted-shape.svg" alt="shape" class="shape shape-1" />
              <img src="assets/images/team/shape-2.svg" alt="shape" class="shape shape-2" /> -->
            </div>
            <!-- <div class="ud-team-info">
              <h5>Andrieo Gloree</h5>
              <h6>App Developer</h6>
            </div>
            <ul class="ud-team-socials">
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-facebook-filled"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-twitter-filled"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-instagram-filled"></i>
                </a>
              </li>
            </ul> -->
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-sm-6">
          <div class="ud-single-team wow fadeInUp" data-wow-delay=".25s">
            <div class="ud-team-image-wrapper">
              <div class="ud-team-image">
                <img src="assets/images/work_refs/6.jpg" alt="team" />
              </div>

              <!-- <img src="assets/images/team/dotted-shape.svg" alt="shape" class="shape shape-1" />
              <img src="assets/images/team/shape-2.svg" alt="shape" class="shape shape-2" /> -->
            </div>
            <!-- <div class="ud-team-info">
              <h5>Jackie Sanders</h5>
              <h6>Content Writer</h6>
            </div>
            <ul class="ud-team-socials">
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-facebook-filled"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-twitter-filled"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/MusharofChy">
                  <i class="lni lni-instagram-filled"></i>
                </a>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Team End ====== -->

  <!-- ====== Contact Start ====== -->
  <section id="contact" class="ud-contact">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-8 col-lg-7">
          <div class="ud-contact-content-wrapper">
            <div class="ud-contact-title">
              <span>CONTACT</span>
              <h2>
                <!-- Let’s talk about <br />
                  Love to hear from you! -->
                Get in touch today.
              </h2>
            </div>
            <div class="ud-contact-info-wrapper">
              <!-- <div class="ud-single-info">
                  <div class="ud-info-icon">
                    <i class="lni lni-map-marker"></i>
                  </div>
                  <div class="ud-info-meta">
                    <h5>Irodánk</h5>
                    <p>1084, Budapest Fecske utca 22</p>
                  </div>
                </div> -->
              <div class="ud-single-info">
                <div class="ud-info-icon">
                  <i class="lni lni-envelope"></i>
                </div>
                <div class="ud-info-meta">
                  <h5>How can I help you?</h5>
                  <p>reddidoggroomer@gmail.com</p>
                </div>
              </div>
              <div class="ud-single-info">
                <div class="ud-info-icon">
                  <i class="lni lni-map"></i>
                </div>
                <div class="ud-info-meta">
                  <h5>Address</h5>
                  <p>Gertrúd Gondoss t/a ReddiDOG</p>
                  <!-- <p>499 Evesham
                    Road Crabbs
                    Cross<br>Redditch,
                    Worcestershire B97 5JJ<span
                      style="font-style: italic; font-weight: 900; color: #e55555;">ReddiDOG</span> is
                    inside <span style="font-style: italic; font-weight: 900;">Paws & Whiskers pet grooming
                      spa</span></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-5">
          <div class="ud-contact-form-wrapper wow fadeInUp" data-wow-delay=".2s">
            <h3 class="ud-contact-form-title">Contact us</h3>
            <form class="ud-contact-form" id="contact-form" action="mail.php" method="post">
              <input type="hidden" id="g-token" name="g-token" />
              <div class="ud-form-group">
                <label for="fullName">Full name<span style="color: red;">*</span></label>
                <input type="text" name="fullName" id="contactName" required [(ngModel)]="name" />
              </div>
              <div class="ud-form-group">
                <label for="email">Email <span style="color: red;">*</span></label>
                <input type="email" name="email" id="contactEmail" required [(ngModel)]="email" />
              </div>
              <div class="ud-form-group">
                <label for="phone">Phone</label>
                <input type="text" name="phone" id="contactPhone" [(ngModel)]="phone" />
              </div>
              <div class="ud-form-group">
                <label for="message">Message <span style="color: red;">*</span></label>
                <textarea name="message" rows="3" id="contactMessage" required [(ngModel)]="message"></textarea>
              </div>
              <div class="ud-form-group mb-0">
                <button type="submit" (click)="sendClick()" class="ud-main-btn">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ====== Contact End ====== -->

  <!-- ====== Footer Start ====== -->
  <!-- <footer class="ud-footer wow fadeInUp" data-wow-delay=".15s">
      <div class="shape shape-1">
        <img src="assets/images/footer/shape-1.svg" alt="shape" />
      </div>
      <div class="shape shape-2">
        <img src="assets/images/footer/shape-2.svg" alt="shape" />
      </div>
      <div class="shape shape-3">
        <img src="assets/images/footer/shape-3.svg" alt="shape" />
      </div>
      <div class="ud-footer-widgets">
        <div class="container">
          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6">
              <div class="ud-widget">
                <a href="index.html" class="ud-footer-logo">
                  <img src="assets/images/logo/ordo_logo_white.svg" alt="logo" />
                </a>
                <p class="ud-widget-desc">
                  Digitális élményeket készítünk vendéglátóhelyek számára a legkorszerűbb
                  technológiák segítségével.
                </p>
                <ul class="ud-widget-socials">
                  <li>
                    <a href="https://facebook.com/ordoeat">
                      <i class="lni lni-facebook-filled"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
  
            <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6">
              <div class="ud-widget">
                <h5 class="ud-widget-title">About Us</h5>
                <ul class="ud-widget-links">
                  <li>
                    <a href="javascript:void(0)">Home</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Features</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">About</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Testimonial</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
              <div class="ud-widget">
                <h5 class="ud-widget-title">Features</h5>
                <ul class="ud-widget-links">
                  <li>
                    <a href="javascript:void(0)">How it works</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Privacy policy</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Terms of service</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">Refund policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-6 col-sm-6">
              <div class="ud-widget">
                <h5 class="ud-widget-title">Our Products</h5>
                <ul class="ud-widget-links">
                  <li>
                    <a href="https://lineicons.com/" rel="nofollow noopner" target="_blank">Lineicons
                    </a>
                  </li>
                  <li>
                    <a href="https://ecommercehtml.com/" rel="nofollow noopner" target="_blank">Ecommerce HTML</a>
                  </li>
                  <li>
                    <a href="https://ayroui.com/" rel="nofollow noopner" target="_blank">Ayro UI</a>
                  </li>
                  <li>
                    <a href="https://graygrids.com/" rel="nofollow noopner" target="_blank">Plain Admin</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-8 col-sm-10">
              <div class="ud-widget">
                <h5 class="ud-widget-title">Partners</h5>
                <ul class="ud-widget-brands">
                  <li>
                    <a href="https://ayroui.com/" rel="nofollow noopner" target="_blank">
                      <img class="img-fixed" src="assets/images/footer/brands/ayroui.svg" alt="ayroui" />
                    </a>
                  </li>
                  <li>
                    <a href="https://ecommercehtml.com/" rel="nofollow noopner" target="_blank">
                      <img class="img-fixed" src="assets/images/footer/brands/ecommerce-html.svg" alt="ecommerce-html" />
                    </a>
                  </li>
                  <li>
                    <a href="https://graygrids.com/" rel="nofollow noopner" target="_blank">
                      <img class="img-fixed" src="assets/images/footer/brands/graygrids.svg" alt="graygrids" />
                    </a>
                  </li>
                  <li>
                    <a href="https://lineicons.com/" rel="nofollow noopner" target="_blank">
                      <img class="img-fixed" src="assets/images/footer/brands/lineicons.svg" alt="lineicons" />
                    </a>
                  </li>
                  <li>
                    <a href="https://uideck.com/" rel="nofollow noopner" target="_blank">
                      <img class="img-fixed" src="assets/images/footer/brands/uideck.svg" alt="uideck" />
                    </a>
                  </li>
                  <li>
                    <a href="https://tailwindtemplates.co/" rel="nofollow noopner" target="_blank">
                      <img class="img-fixed" src="assets/images/footer/brands/tailwindtemplates.svg"
                        alt="tailwindtemplates" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ud-footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <ul class="ud-footer-bottom-left">
                <li>
                  <a href="javascript:void(0)">Privacy policy</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Support policy</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Terms of service</a>
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <p class="ud-footer-bottom-right">
                Designed and Developed by
                <a href="https://uideck.com" rel="nofollow">UIdeck</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer> -->
  <!-- ====== Footer End ====== -->

  <!-- ====== Accept Cookies Start ====== -->
  <div class="cookie-wrapper hide">
    <!-- <img src="#" alt=""> -->
    <div class="cookie-content">
      <header>Cookie-k hozzájárulása</header>
      <p>Ez a weboldal cookie-kat használ annak érdekében, hogy a lehető legjobb élményt nyújthassa webhelyünkön.
      </p>
      <div class="cookie-buttons">
        <button class="item">Elfogadom</button>
        <a href="cookie-policy.html" class="item">Infók</a>
      </div>
    </div>
  </div>
  <!-- ====== Accept Cookies End ====== -->

  <!-- ====== Back To Top Start ====== -->
  <!-- <a href="javascript:void(0)" class="back-to-top">
    <i class="lni lni-chevron-up"> </i>
  </a> -->
  <!-- ====== Back To Top End ====== -->

  <!-- ====== All Javascript Files ====== -->
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js" type="text/javascript"></script>
  <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
  <script src="https://smtpjs.com/v3/smtp.js"></script>
  <script src="https://unpkg.com/@lottiefiles/lottie-interactivity@latest/dist/lottie-interactivity.min.js"></script>
  <script src='https://www.google.com/recaptcha/api.js'></script>
  <script src="https://www.gstatic.com/firebasejs/9.12.1/firebase-app.js"></script>
  <script src="https://www.gstatic.com/firebasejs/9.12.1/firebase-analytics.js"></script>

  <script src="assets/js/bootstrap.bundle.min.js"></script>
  <script src="assets/js/wow.min.js"></script>
  <script src="assets/js/main.js"></script>

  <script>
    grecaptcha.ready(function () {
      grecaptcha.execute('6LeG5LQlAAAAAJCvs18yCr5Ht3pEfc4HKMkegVoD', { action: 'homepage' }).then(function (token) {
        document.getElementById("g-token").value = token;
      });
    });
  </script>

</body>