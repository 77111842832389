import { DogType, DurationType, Price, ServiceType } from "./models/shared.models";

export class MockData {
    prices: Price[] = [
        {
            id: 1,
            service: 'Short Hair',
            data: [
                {
                    size: 'X Small',
                    weight: '1-3 lbs',
                    descr: [
                        'Chihuahua', 'Miniature Pinscher', '£15'
                    ]
                },
                {
                    size: 'Small',
                    weight: '3-5 lbs',
                    descr: [
                        'French Bulldog', 'Pug', 'Jack Russel', '£20'
                    ]
                },
                {
                    size: 'Medium',
                    weight: '5-8 lbs',
                    descr: [
                        'Straffordshire Bull Terrier', 'Kelpie', '£25'
                    ]
                },
                {
                    size: 'Large',
                    weight: '8-10 lbs',
                    descr: [
                        'Labrador', 'Dalmatian', 'Vizla', 'Boxer', '£35'
                    ]
                },
                {
                    size: 'X Large',
                    weight: '10+ lbs',
                    descr: [
                        'Great Dane', 'Bull Mastiff', '£45'
                    ]
                }
            ],
        },
        {
            id: 2,
            service: 'Long Hair',
            data: [
                {
                    size: 'X Small',
                    weight: '1-5 lbs',
                    descr: [
                        'Long Hair Chihuahua', 'X Small Yorkshire Terrier', '£20'
                    ]
                },
                {
                    size: 'Small',
                    weight: '5-10 lbs',
                    descr: [
                        'Shi-zhu', 'Bichon', 'Yorkshire Terrier', 'Mini Schnauzer', '£25'
                    ]
                },
                {
                    size: 'Medium',
                    weight: '10-15 lbs',
                    descr: [
                        'Cocker Spaniel', 'Cockapoo', '£30'
                    ]
                },
                {
                    size: 'Large',
                    weight: '15-20 lbs',
                    descr: [
                        'Standard Poodle', 'Border Collie', 'Setter', '£35'
                    ]
                },
                {
                    size: 'X Large',
                    weight: '25+ lbs',
                    descr: [
                        'Newfoundland', '£50'
                    ]
                }
            ]
        },
        {
            id: 3,
            service: 'Handstripping',
            data: [
                {
                    size: 'X Small',
                    weight: '1-5 lbs',

                    descr: [
                    ]
                },
                {
                    size: 'Small',
                    weight: '5-10 lbs',
                    descr: [
                        'Border Terrier', 'Westie', '£30'
                    ]
                },
                {
                    size: 'Medium',
                    weight: '10-15 lbs',
                    descr: [
                        'Welsh Terrier', '£35'
                    ]
                },
                {
                    size: 'Large',
                    weight: '15-20 lbs',
                    descr: [
                        'Golden Retriever', 'Huskey', '£45'
                    ]
                },
                {
                    size: 'X Large',
                    weight: '25+ lbs',
                    descr: [
                        'Airedale', '£55'
                    ]
                }
            ]
        }
    ];

    durations: DurationType[] = [
        {
            id: 1,
            dogId: 1,
            serviceId: 1,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 1,
            durationText: "2:00",
            durationTextSalon: "1:00"
        },
        {
            id: 2,
            dogId: 2,
            serviceId: 1,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 1,
            durationText: "2:00",
            durationTextSalon: "1:00"
        },
        {
            id: 3,
            dogId: 3,
            serviceId: 1,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 1,
            durationText: "2:00",
            durationTextSalon: "1:00"
        },
        {
            id: 4,
            dogId: 4,
            serviceId: 1,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 1,
            durationText: "2:00",
            durationTextSalon: "1:00"
        },
        {
            id: 5,
            dogId: 5,
            serviceId: 1,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 1,
            durationText: "2:00",
            durationTextSalon: "1:00"
        },
        {
            id: 6,
            dogId: 1,
            serviceId: 2,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 1,
            durationText: "2:00",
            durationTextSalon: "1:00"
        },
        {
            id: 7,
            dogId: 2,
            serviceId: 2,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 1,
            durationText: "2:00",
            durationTextSalon: "1:00"
        },
        {
            id: 8,
            dogId: 3,
            serviceId: 2,
            durationHour: 3,
            durationMin: 0,
            durationHourSalon: 2,
            durationText: "3:00",
            durationTextSalon: "2:00"
        },
        {
            id: 9,
            dogId: 4,
            serviceId: 2,
            durationHour: 4,
            durationMin: 0,
            durationHourSalon: 3,
            durationText: "4:00",
            durationTextSalon: "3:00"
        },
        {
            id: 10,
            dogId: 5,
            serviceId: 2,
            durationHour: 4,
            durationMin: 0,
            durationHourSalon: 3,
            durationText: "4:00",
            durationTextSalon: "3:00"
        },
        {
            id: 11,
            dogId: 1,
            serviceId: 3,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 1,
            durationText: "2:00",
            durationTextSalon: "1:00"
        },
        {
            id: 12,
            dogId: 2,
            serviceId: 3,
            durationHour: 3,
            durationMin: 0,
            durationHourSalon: 2,
            durationText: "3:00",
            durationTextSalon: "2:00"
        },
        {
            id: 13,
            dogId: 3,
            serviceId: 3,
            durationHour: 2,
            durationMin: 0,
            durationHourSalon: 2,
            durationText: "3:00",
            durationTextSalon: "2:00"
        },
        {
            id: 14,
            dogId: 4,
            serviceId: 3,
            durationHour: 3,
            durationMin: 0,
            durationHourSalon: 2,
            durationText: "3:00",
            durationTextSalon: "2:00"
        },
        {
            id: 15,
            dogId: 5,
            serviceId: 3,
            durationHour: 4,
            durationMin: 0,
            durationHourSalon: 3,
            durationText: "4:00",
            durationTextSalon: "3:00"
        }
    ];

    dogTypes: DogType[] = [
        {
            id: 1,
            name: "X Small 1 - 3 kg",
            price: 15
        },
        {
            id: 2,
            name: "Small 3 - 5 kg",
            price: 20
        },
        {
            id: 3,
            name: "Medium 5 - 8 lbs",
            price: 25
        },
        {
            id: 4,
            name: "Large 8 - 10 lbs",
            price: 35
        },
        {
            id: 5,
            name: "X Large 10+ lbs",
            price: 45
        }
    ];

    services: ServiceType[] = [
        {
            id: 1,
            name: "Short Coat / Short Hair",
            price: 0
        },
        {
            id: 2,
            name: "Long Hair / Silk / Wool",
            price: 5
        },
        {
            id: 3,
            name: "Double Coat / Handstripping",
            price: 10
        }
    ];
}