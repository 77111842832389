import { Component } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent {

  email: string = "";
  password: string = "";

  constructor(
    private authService: AuthService
  ) { }

  signInClick() {
    this.authService.SignIn(this.email, this.password);
  }

}
