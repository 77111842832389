<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

<header class="header">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
            <a href="#">
                <img src="../assets/images/logo_color.svg" alt="Logo" height="70" />
            </a>
        </div>
    </nav>
</header>

<div class="container-body" *ngIf="status == 'success'">
    <h1>Booking Verification</h1>
    <p>Your booking has been successfully verified.</p>
    <button (click)="okClick()">OK</button>
</div>

<div class="container-body" *ngIf="status == 'already_verified'">
    <h1>Booking already completed</h1>
    <p>Your booking has already been made.</p>
    <button (click)="okClick()">OK</button>
</div>

<div class="container-body" *ngIf="status == 'time_over'">
    <h1>Booking deleted</h1>
    <p>Your time to verify your booking, has ended, please book again.</p>
    <button (click)="okClick()">OK</button>
</div>

<div class="container-body" *ngIf="status == 'error'">
    <h1>Booking error</h1>
    <p>Something went wrong with the provided id.</p>
    <button (click)="okClick()">OK</button>
</div>