<nav class="navbar">
    <div class="logo">
        <h4 style="color: black; font-size: 34px; font-weight: 900;">Admin</h4>
    </div>
    <ul class="menu">
        <li><a href="admin/appointments">Foglalások</a></li>
        <li><a href="admin/leave-manager">Szabadságok</a></li>
    </ul>
    <div class="logout">
        <a href="auth" (click)="authService.SignOut()">Kijelentkezés</a>
    </div>
</nav>
<hr>

<router-outlet></router-outlet>