import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EmailTemplate } from 'src/app/shared/email-template';
import Swal from 'sweetalert2';

export class Appointment {
  address: string;
  postcode: string;
  date: any;
  time: string;
  datetime: string;
  dogType: string;
  email: string;
  name: string;
  phone: string;
  price: number;
  service: string;
  dogName: string;
  dogAge: string;
  dogBreed: string;
  emailVerified: boolean;
  validUntil: any;
  withBath: boolean;
  inSalon: boolean;
  id: string;
}

@Component({
  selector: 'app-appointment-page',
  templateUrl: './appointment-page.component.html',
  styleUrls: ['./appointment-page.component.scss'],
})
export class AppointmentPageComponent implements OnInit {
  selectedDate = new Date();

  appointments: Appointment[];

  constructor(private firestore: AngularFirestore) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    this.appointments = [];
    const start = formatDate(this.selectedDate.setDate(1), 'yyyy-MM-dd', 'en');
    const tmpStart = new Date(start);
    const end = formatDate(
      tmpStart.setMonth(tmpStart.getMonth() + 1),
      'yyyy-MM-dd',
      'en'
    );

    let res = await this.firestore
      .collection('bookings', (ref) =>
        ref
          .where('date', '>=', new Date(start))
          .where('date', '<', new Date(end))
      )
      .get()
      .toPromise();

    for (var doc of res.docs) {
      const data = doc.data();

      if (data['validUntil'].toDate() < new Date() && !data['emailVerified']) {
        await this.firestore.collection('bookings').doc(doc.id).delete();
        continue;
      }

      var appointment: Appointment = data as Appointment;
      appointment.id = doc.id;
      this.appointments.push(appointment);
    }
  }

  onDateChanged(event) {
    event += '-01';
    let splitted = event.split('-');
    this.selectedDate = new Date(splitted[0], splitted[1] - 1, splitted[2]);
    this.getData();
  }

  async cancelClick(appointment: Appointment) {
    let res = await Swal.fire({
      title: 'Biztosan lemondod?',
      confirmButtonText: 'Küldés',
      cancelButtonText: 'Mégsem',
      showCancelButton: true,
    });
    if (!res.isConfirmed) return;

    await this.firestore.collection('mail').add({
      to: appointment.email,
      message: {
        subject: 'Appointment Cancellation',
        html: new EmailTemplate().getCancelEmail(
          res.value,
          appointment.name,
          formatDate(appointment.date.toDate(), 'dd/MM/yyyy h:mm a', 'en')
        ),
      },
    });

    await this.firestore.collection('bookings').doc(appointment.id).delete();

    Swal.fire('Siker', 'Foglalást sikeresen lemondtad!', 'success');

    let index = this.appointments.indexOf(appointment);
    this.appointments.splice(index, 1);
  }
}
