<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

<header class="header">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
            <!-- Navbar Brand -->
            <a href="#">
                <img src="../assets/images/logo_color.svg" alt="Logo" height="70" />
                <!-- <h4 style="color: black; font-size: 34px; font-weight: 900;">ReddiDog</h4> -->
            </a>
            <div *ngIf="getDurationText() != null">
                <h6 style="color: black; font-size: 34px;">ETC: {{getDurationText()}} hours</h6>
            </div>
            <div></div>
        </div>
    </nav>
</header>


<div class="container">
    <div class="row py-5 mb-4 align-items-center" style="align-items: center;">
        <!-- For Demo Purpose -->
        <div class="col-md-5 pr-lg-5 mb-5 mb-md-0">
            <img src="assets/images/illustrations/dog_groom_2.png" alt="" class="img-fluid mb-3 d-none d-md-block"
                style="align-items: center;">
            <!-- <img src="https://bootstrapious.com/i/snippets/sn-registeration/illustration.svg" alt=""
                class="img-fluid mb-3 d-none d-md-block"> -->
            <h1>Finalize Booking</h1>
            <p class="font-italic text-muted">By finalizing the booking, you accept the <a href="terms-and-services"
                    class="text-muted">
                    <u>Terms and Conditions</u></a>
            </p>
        </div>

        <!-- Registeration Form -->
        <div class="col-md-7 col-lg-6 ml-auto">
            <form action="#">
                <div class="row">

                    <div *ngIf="page == 0">

                        <!-- <div class="col-lg-12 mb-1">
                            <input type="checkbox" id="insalon" name="insalon" [(ngModel)]="inSalon"
                                style="height: 20px; width: 20px;" (change)="onSalonChange($event.target.checked)">
                            <label for="insalon" style="margin-left: 5px; font-size: 20px;">In Salon</label><br>
                        </div>
                        <div class="col-lg-12 mb-4" style="font-size: 16px; font-weight: 900;">499
                            Evesham
                            Road Crabbs
                            Cross<br>Redditch,
                            Worcestershire B97 5JJ</div>
                        <div class="col-lg-12 mb-4" style="font-size: 16px; ">
                            <span style="font-style: italic; font-weight: 900; color: #e55555;">ReddiDOG</span> is
                            inside <span style="font-style: italic; font-weight: 900;">Paws & Whiskers pet grooming
                                spa</span>
                        </div> -->
                        <div class="input-group col-lg-6 mb-4">
                            <span class="input-group-text bg-white px-4 border-md border-right-0">
                                <i class="fa fa-user text-muted"></i>
                            </span>
                            <input id="fullName" type="text" name="fullname" placeholder="Full Name"
                                class="form-control bg-white border-left-0 border-md" [(ngModel)]="fullName">
                        </div>

                        <div class="input-group col-lg-6 mb-4">
                            <span class="input-group-text bg-white px-4 border-md border-right-0">
                                <i class=" fa fa-envelope text-muted"></i>
                            </span>
                            <input id="email" type="email" name="email" placeholder="Email Address"
                                class="form-control bg-white border-left-0 border-md" [(ngModel)]="email">
                        </div>

                        <div *ngIf="!inSalon">
                            <div class="col-lg-12 mb-4 input-group">
                                <span class="input-group-text bg-white px-4 border-md border-right-0">
                                    <i class="fa fa-location text-muted"></i>
                                </span>
                                <input id="postcode" type="text" name="postcode" placeholder="Postcode"
                                    class="form-control bg-white border-left-0 border-md" [(ngModel)]="postcode">
                            </div>
                            <div class="col-lg-12 mb-4 input-group">
                                <span class="input-group-text bg-white px-4 border-md border-right-0">
                                    <i class="fa fa-location text-muted"></i>
                                </span>
                                <input id="address" type="text" name="address" placeholder="Address"
                                    class="form-control bg-white border-left-0 border-md" [(ngModel)]="address">
                            </div>
                        </div>



                        <div class="input-group col-lg-12 mb-4">
                            <span class="input-group-text bg-white px-4 border-md border-right-0">
                                <i class="fa fa-phone-square text-muted"></i>
                            </span>
                            <input id="phoneNumber" type="tel" name="phone" placeholder="Phone Number"
                                class="form-control bg-white border-md border-left-0 pl-3" [(ngModel)]="phone">
                        </div>

                        <div class="input-group col-lg-12 mb-4">
                            <span class="input-group-text bg-white px-4 border-md border-right-0">
                                <i class="fa fa-paw text-muted"></i>
                            </span>
                            <input id="dogName" type="text" name="dogName" placeholder="Dog's Name"
                                class="form-control bg-white border-md border-left-0 pl-3" [(ngModel)]="dogName">
                            <input id="dogAge" type="text" name="dogAge" placeholder="Dog's Age"
                                class="form-control bg-white border-md border-left-0 pl-3" [(ngModel)]="dogAge">
                            <input id="dogBreed" type="text" name="dogBreed" placeholder="Dog's Breed"
                                class="form-control bg-white border-md border-left-0 pl-3" [(ngModel)]="dogBreed">
                        </div>

                        <div class="input-group col-lg-12 mb-4">
                            <span class="input-group-text bg-white px-4 border-md border-right-0">
                                <i class="fa fa-dog text-muted"></i>
                            </span>
                            <select id="dogtype" name="jobtitle"
                                class="form-control custom-select bg-white border-left-0 border-md"
                                (change)="onDogTypeChange($event.target.value)">
                                <option value="">Choose Dog Type</option>
                                <option *ngFor="let type of dogTypes" [value]="type.id">{{type.name}}</option>
                            </select>
                        </div>

                        <div class="input-group col-lg-12 mb-4">
                            <span class="input-group-text bg-white px-4 border-md border-right-0">
                                <i class="fa fa-scissors text-muted"></i>
                            </span>
                            <select id="service" name="jobtitle"
                                class="form-control custom-select bg-white border-left-0 border-md"
                                (change)="onServiceChange($event.target.value)">
                                <option value="">Choose service</option>
                                <option *ngFor="let service of services" [value]="service.id">{{service.name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-lg-12 mb-4">
                            <input type="checkbox" id="bath" name="bath" [(ngModel)]="withBath"
                                style="height: 20px; width: 20px;" (change)="onBathChange($event.target.checked)">
                            <label for="bath" style="margin-left: 5px; font-size: 20px;">With bath</label><br>
                        </div>
                    </div>

                    <div *ngIf="page == 1" class="my-4">
                        <div class="col-lg-12" style="display: flex; flex-direction: row; justify-content: center;">
                            <input type="hidden" name="mydate" placeholder="Select a date" angular-mydatepicker
                                #dp="angular-mydatepicker" [(ngModel)]="model" [options]="myDpOptions"
                                (dateChanged)="onDateChanged($event)" />
                        </div>
                        <div class="row mt-2" *ngIf="timeslots != null">
                            <div class="col-lg-2 col-4 timeslot-container" *ngFor="let slot of timeslots">
                                <div *ngIf="!slot.booked" class="available-timeslot" style="font-size: 14px;"
                                    [ngClass]="slot.selected ? 'available-timeslot-selected' : 'available-timeslot'"
                                    (click)="selectTimeslot(slot)">{{formatTime(slot.time)}}</div>
                                <div *ngIf="slot.booked" class="available-timeslot-booked" style="font-size: 14px;">
                                    {{formatTime(slot.time)}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mb-4">
                        <h6>Total Price</h6>
                        <h4>£ {{getPrice()}}</h4>
                    </div>

                    <!-- Submit Button -->
                    <div class="form-group col-lg-12"
                        style="display: flex; flex-direction: row; justify-content: center;">
                        <div *ngIf="page == 1" class="btn btn-danger btn-block py-2 px-4 mx-4" (click)="page = 0">
                            <span class="font-weight-bold">Back</span>
                        </div>
                        <div class="btn btn-primary btn-block py-2" (click)="finishClick()">
                            <span class="font-weight-bold" *ngIf="page == 0">Select Time</span>
                            <span class="font-weight-bold" *ngIf="page == 1">Confirm Appointment</span>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>